import React, { useEffect, useState } from 'react';
import Authuser from '../../api/Authuser';

export default function Pricedetails(props) {
const {http,bUrl} = Authuser();
const token = localStorage.getItem('token');
const [countrydetails,setCountrydetails] = useState([]); 
const [coindetails,setCoindetails] = useState([]);
const [coinvalue,setCoinvalue] = useState(0);
useEffect(()=>{
    getcoindetails();
    getusercountrydetails();
    setCoinvalue((parseFloat(props.amount)+parseFloat(props.totalbuy)+parseFloat(props.incentive))-parseFloat(props.wamount)-+parseFloat(props.totalsell));
},[]);
const getcoindetails = () =>{
    http.get('/coin-details/'+props.coincode).then(res=>{
        setCoindetails(res.data);
        console.log(res.data);
      });
}
const getusercountrydetails = () =>{
    http.get('/user-country-details/'+token).then(res=>{
        setCountrydetails(res.data);
    });
}


  return (
    <>
        <img src={`${bUrl}storage/${coindetails.symbol}`} style={{width:'25px', height:'25px'}} />
        &nbsp; &nbsp;
        <div className="alert-text w-25">
        <h6 className="text-truncate text-white">{props.coincode}<br/><span className="text-white">{coindetails.coinname}</span></h6>
        </div>
        <div className="alert-text w-75 text-right">
        <h6 className="text-white" style={{textAlign:'right'}}>
        {parseFloat(coinvalue.toFixed(6))}
        </h6>
        {props.coincode==='USDT' ?
        <span className="text-white" style={{textAlign:'right'}}>
        <img src={`${bUrl}storage/${countrydetails.symbol}`} style={{height:'11px'}} />
        {parseFloat(coinvalue*countrydetails.normalvalue).toFixed(2)}
        </span>
        :
        <span className="text-white" style={{textAlign:'right'}}>
        <img src={`${bUrl}storage/${countrydetails.symbol}`} style={{height:'11px'}} />
        {parseFloat(coinvalue*countrydetails.normalvalue*coindetails.usdtprice).toFixed(2)}
        </span>
        }
        </div>

    </>
  )
}
