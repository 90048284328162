import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation';
import Authuser from '../../api/Authuser';

export default function Orders() {

const {http,bUrl} = Authuser();
const navigate = useNavigate();
const token = localStorage.getItem('token');
const [page,setPage] = useState(10);
const [hasMore,setHasMore] = useState(true);
const [openorders,setOpenorders] = useState([]);
const [historyorders,setHistoryorders] = useState([]);

useEffect(()=>{
    getorderdetails();
},[]);

const getorderdetails = ()=>{
    http.get('/orders-detail/'+token+'/'+page).then(res=>{
        setOpenorders(res.data.openorder);
        setHistoryorders(res.data.historyorder.data);
    });
}

const seemore = ()=>{
  let mdata = page + 50;
  setPage(page+50);
  http.get('/orders-detail/'+token+'/'+mdata).then(res=>{
        setHistoryorders(res.data.historyorder.data);
      });
}

const ordercancel = (orderid) =>{
  http.get('/orders-cancel/'+orderid).then(res=>{
    getorderdetails();
  });
}


  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >   
<Helmet>
    <title>Maxpayz | Orders</title>
    <link rel="stylesheet" href="/assets/css/orders.css" />
</Helmet> 

<div className="page-content-wrapper py-1">
<div className="w-100">
<div className="card">
<div className="card-body" style={{background:'#1F016B'}}>
<h6 className="text-center text-white">My Orders</h6>
<div className="standard-tab">
    <ul className="nav rounded-lg mb-2 p-2 shadow-sm" id="affanTabs1" role="tablist">
    <li className="nav-item" role="presentation">
        <button className="btn active show text-white" id="bootstrap-tab" data-bs-toggle="tab" data-bs-target="#bootstrap" type="button" role="tab" aria-controls="bootstrap" aria-selected="true">
            Open 
        </button>
    </li>
    <li className="nav-item" role="presentation">
        <button className="btn  text-white" id="pwa-tab" data-bs-toggle="tab" data-bs-target="#pwa" type="button" role="tab" aria-controls="pwa" aria-selected="false">
            History
        </button>
    </li>
    </ul>
    <div className="tab-content rounded-lg shadow-sm" id="affanTabs1Content">
    <div className="tab-pane fade show active show text-center" id="bootstrap" role="tabpanel" aria-labelledby="bootstrap-tab">
    <table className="table mb-0 table-striped">
              <thead style={{background: '#000035', color:'white'}}>
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Details</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
              {openorders.map((item,index)=>( 
              <tr style={{borderBottom:'0.5px solid white !important'}} key={item.id}>
                  <td>
                  {item.type==='Buy' ?
                  <span className="text-success">BUY</span> :
                  <span className="text-danger">SELL</span>
                  }
                  <br/>
                  <span style={{color:'#0D86BB', lineHeight: '65px'}}>{parseFloat(item.left_percent)}%</span>
                  </td>

                  <td>
                  <span style={{color:'#BCBDBD', textAlign:'center'}}>{item.coin_code} / {item.pair_name}</span><br/>
                  <span style={{color:'#BCBDBD', float:'left', fontSize:'12px'}}>Qnty:</span>
                  <span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}> 
                  {parseFloat((item.qty*item.left_percent)/100)}/{parseFloat(item.qty)}</span><br/>
                  <span style={{color:'#BCBDBD', float:'left', fontSize:'12px'}}>Price:</span><span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}>  {parseFloat(item.at_price)}</span><br/>
                  <span style={{color:'#BCBDBD', float:'left', fontSize:'12px'}}>Total:</span><span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}>  {parseFloat(item.total)}</span>
                  </td>

                  <td>
                  <span style={{color:'#BCBDBD'}}>{item.date}</span><br/>
                  <span style={{color:'#BCBDBD'}}>{item.time}</span><br/>
                  <span className="badge bg-info" onClick={()=>ordercancel(item.id)} >Cancel</span>
                  </td>

              </tr>
              ))}
              </tbody>
            </table>
    </div>
    {/* <!-- Open End --> */}

    {/* <!-- History Start --> */}
    <div className="tab-pane fade  text-white text-center" id="pwa" role="tabpanel" aria-labelledby="pwa-tab">
    <InfiniteScroll
    dataLength={historyorders.length}
    next={seemore}
    hasMore={hasMore}  
    >
    <table className="table mb-0 table-striped">
              <thead style={{background: '#000035', color:'white'}}>
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Details</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
              {historyorders.map((item,index)=>( 
                <tr style={{borderBottom:'0.5px solid white !important'}} key={item.id}>
                  <td>
                  {item.type==='Buy' ?
                  <span className="text-success">BUY</span> :
                  <span className="text-danger">SELL</span>
                  }
                  <br/>
                  {100-(item.left_percent)==0 ?
                  <span style={{color:'#0D86BB', lineHeight: '65px'}}>100%</span> :
                  <span style={{color:'#0D86BB', lineHeight: '65px'}}>{100-(item.left_percent)}%</span>
                  }
                  </td>

                  <td>
                  <span style={{color:'#BCBDBD', textAlign:'center'}}>{item.coin_code} / {item.pair_name}</span><br/>
                  <span style={{color:'#BCBDBD', float:'left', fontSize:'12px'}}>Qnty:</span>
                  {(item.qty-item.left_qty)===0 ?
                  <span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}> 
                  {item.qty}
                  </span> :
                  <span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}> 
                  {item.qty-item.left_qty}
                  </span> 
                  }
                  <br/>
                  <span style={{color:'#BCBDBD', float:'left', fontSize:'12px'}}>Price:</span><span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}>  {parseFloat(item.at_price)}</span><br/>
                  <span style={{color:'#BCBDBD', float:'left', fontSize:'12px'}}>Total:</span>
                  {item.status===3 ? 
                  <span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}>  
                  {parseFloat(item.total-item.left_total)}
                  </span> :
                  <span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}>  
                  {parseFloat(item.total)}
                  </span>
                  }
                  </td>

                  <td>
                  <span style={{color:'#BCBDBD'}}>{item.date}</span><br/>
                  <span style={{color:'#BCBDBD'}}>{item.time}</span><br/>
                  {item.status===0 ?
                  <span className="badge bg-danger">Cancelled</span> :
                  item.status===2 && item.type==='Buy' ?
                  <span className="badge bg-success">Completed</span> :
                  item.status===2 && item.type==='Sell' ?
                  <span className="badge bg-success">Completed</span> :
                  <span className="badge bg-info">Partially<br/>Completed</span> 
                  }
                  </td>

                </tr>
              ))}
              </tbody>
            </table>
            </InfiniteScroll>
    </div>
    </div>
</div>
</div>
</div>
</div>
</div>

    </motion.div>
  )
}
