import React, { useEffect } from 'react';
import { Routes,Route, useNavigate } from 'react-router-dom';
import Protected from '../components/Protected';
import Intro from '../pages/intro/Intro';
import Login from '../pages/login/Login';
import Register from '../pages/register/Register';
import Dashboard from '../pages/dashboard/Dashboard';

export default function Guest() {

  return (
    <>

<Routes>
    <Route path='/' element={<Intro />} />
    <Route path='/login' element={<Login />} />
    <Route path='/register' element={<Register />} />
    <Route path='/dashboard' element={<Protected Component={Dashboard} />} />
</Routes>

    </>
  )
}
