import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation';
import Authuser from '../../api/Authuser';
import { Link } from 'react-router-dom';
import Userdetailsbyid from './Userdetailsbyid';

export default function Refferearn() {

const {http,bUrl,murl} = Authuser();
const token = localStorage.getItem('token');
const [profiledetails,setProfiledetails] = useState([]);  
const [reffdetails,setReffdetails] = useState([]);  
const [hasMore,setHasMore] = useState(true);
const [rpage,setRpage] = useState(10);
const [incentivedetails,setIncentivedetails] = useState([]);  
const [ipage,setIpage] = useState(10);
const [totaltodayincentive,settotaltodayincentive] = useState([]); 
useEffect(()=>{
    getprofiledetails();
    getreffdetails();
    getincentivedetails();
    gettotaltodayincentive();
},[]); 

const getprofiledetails = () =>{
    http.get('/profile-details/'+token).then(res=>{
        setProfiledetails(res.data);
    });
};

const getreffdetails = () =>{
  http.get('/reff-details/'+token+'/'+rpage).then(res=>{
    setReffdetails(res.data.data);
  });
}; 

const getincentivedetails = () =>{
  http.get('/incentive-details/'+token+'/'+ipage).then(res=>{
    setIncentivedetails(res.data.data);
    console.log(res.data.data);
  });
};

const rfetchmoreData = ()=>{
  let mdata = rpage + 5;
  setRpage(rpage+5);
      http.get('/reff-details/'+token+'/'+mdata).then(res=>{
        setReffdetails(res.data.data);
        console.log(res.data.data);
      });
}

const gettotaltodayincentive = () =>{
  http.get('/total-today-incentive/'+token).then(res=>{
    settotaltodayincentive(res.data);
  });
}

  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >

<div className="header-area" id="headerArea" style={{background:'#000035'}}>
<div className="container">

<div className="header-content position-relative d-flex align-items-center justify-content-between">

<div className="back-button"><Link to="/profile" className="text-white">
<svg className="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
</svg></Link></div>

<div className="page-heading">
<h6 className="mb-0 text-white" style={{fontWeight:'bolder', fontSize:'17px'}}>Share Your Link & Earn More</h6>
</div>

<div className="setting-wrapper"><a className="setting-trigger-btn" id="settingTriggerBtn" href="#">
</a></div>
</div>
</div>
</div>

<div className="page-content-wrapper py-3">
<div className="container">

<div className="card" style={{background:'#05002E'}}>
    <div className="card-body direction-rtl">
    <center><input type="text" className="form-control text-center" id="myInput" value={`${murl}account/${profiledetails.encodeuserid}`}  readonly='readonly' /></center>
    </div>
    <div className="card-footer text-center">
    <a className="btn btn-primary" style={{background:'#2568B7'}} target="_blank" href={`http://www.facebook.com/sharer.php?p[url]=${murl}account/${profiledetails.encodeuserid}`} title="Share Via Facebook"><i className="fa fa-facebook fa-2x"></i></a>
    <a style={{background:'#01EF52'}} href={`https://api.whatsapp.com/send?text=${murl}account/${profiledetails.encodeuserid}`} className="btn btn-success" target="_blank" data-action="share/whatsapp/share" title="Share Via WhatsApp"><i className="fa fa-whatsapp fa-2x"></i></a>
    {/* <button onclick="Hello()" className="btn btn-success" title="Copy Link"><i className="fa fa-clipboard fa-2x"></i></button> */}
    <a className="btn btn-info" style={{background:'#05002D'}} target="_blank" href={`http://www.linkedin.com/shareArticle?url=${murl}account/${profiledetails.encodeuserid}`} title="Share Via Linkedin"><i className="fa fa-linkedin fa-2x"></i></a>
    <a className="btn btn-primary" style={{background:'#1BAFE1'}} target="_blank" href={`https://twitter.com/intent/tweet?text=${murl}account/${profiledetails.encodeuserid}`} title="Share Via Twitter"><i className="fa fa-twitter fa-2x"></i></a>
    </div>
</div><br/>

<div className="card" style={{background:'#05002E'}}>
    <div className="card-header">
        <h6 className="text-center text-white">Refferals</h6>
    </div>
    <div className="card-body direction-rtl text-center">
    <div className="row">
              <div className="col-4">
                
                <div className="single-counter-wrap text-center">
                  <h4 className="mb-0 text-warning"><span className="text-white">{profiledetails.directteam}</span></h4><span className="solid-line"></span>
                  <p className="mb-0 text-white">Self</p>
                </div>
              </div>
              <div className="col-4">
                
                <div className="single-counter-wrap text-center">
                  <h4 className="mb-0 text-warning"><span className="text-white">{profiledetails.groupteam}</span></h4><span className="solid-line"></span>
                  <p className="mb-0 text-white">Group</p>
                </div>
              </div>
              <div className="col-4">

                <div className="single-counter-wrap text-center">
                  <h4 className="mb-0 text-warning"><span className="text-white">{profiledetails.totalteam}</span></h4><span className="solid-line"></span>
                  <p className="mb-0 text-white">Total</p>
                </div>
              </div>
            </div>
    </div>
</div><br/> 

<div className="card" style={{background:'#05002E'}}>
  <div className="card-body">
  <InfiniteScroll
        dataLength={reffdetails.length}
        next={rfetchmoreData}
        hasMore={hasMore}  
        >
    <table className="table table-responsive text-white text-center gen_table" style={{height: '40px', overflowY: 'scroll'}}>
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">ID</th>
          <th scope="col" style={{textAlign:'right'}}>Gen</th>
        </tr>
      </thead>
      <tbody style={{fontSize: '11px'}}>
          {reffdetails.map((item,index)=>(
            <tr>
              <th scope="row">{new Date(item.created_at).toDateString()}</th>
              <Userdetailsbyid userid={item.member_id} />
              <td style={{textAlign:'right'}}>{item.gen_type}</td>
            </tr>
          ))}
      </tbody>
    </table>
    </InfiniteScroll>
  </div>
</div><br/>


<div className="card" style={{background:'#05002E'}}>
    <div className="card-header">
        <h6 className="text-center text-white">
          Total Incentive: <img src={`${bUrl}storage/${totaltodayincentive.currencywsymbol}`} style={{width:'25px', height:'15px'}} /> {(totaltodayincentive.totalincentive)}
        </h6>
        <h6 className="text-center text-white">
          Today's Incentive: <img src={`${bUrl}storage/${totaltodayincentive.currencywsymbol}`} style={{width:'25px', height:'15px'}} /> {(totaltodayincentive.todayincentive)}
        </h6>
    </div>
</div>
<div className="card" style={{background:'#05002E'}}> 
  <div className="card-body">
    <table className="table table-responsive text-white text-center gen_table" style={{height: '50px', overflowY: 'scroll'}}>
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Refferal</th>
          <th scope="col" style={{textAlign:'right'}}>Incentive</th>
        </tr>
      </thead>
      <tbody style={{fontSize: '10px'}}>
        {incentivedetails.map((item,index)=>(
            <tr>
              <th scope="row">{new Date(item.created_at).toDateString()}</th>
              <Userdetailsbyid userid={item.from_user_id} />
              <td style={{textAlign:'right'}}>{parseFloat(item.incentive)} {item.coin_code}</td>
            </tr>
          ))}
      </tbody>
    </table>
    <center></center>
  </div>
</div>


</div>
</div>

    </motion.div>
  )
}
