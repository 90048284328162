import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation';
import Authuser from '../../api/Authuser';
import { Link } from 'react-router-dom';

export default function Transactionhistory() {

const {http,bUrl,murl,getToken,logout} = Authuser();
const token = localStorage.getItem('token');
const [buyselld,setBuyselld] = useState([]);
const [depowithd,setDepowithd] = useState([]);
useEffect(()=>{
  gettrandetails();
},[]);

const gettrandetails = () =>{
  http.get('/transaction-history/'+token).then(res=>{
    setBuyselld(res.data.buysell);
    setDepowithd(res.data.depowith);
});
}


  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >

<div className="header-area" id="headerArea" style={{background:'#000035'}}>
<div className="container">

<div className="header-content position-relative d-flex align-items-center justify-content-between">

<div className="back-button"><Link to="/profile" className="text-white">
<svg className="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
</svg></Link></div>

<div className="page-heading">
<h6 className="mb-0 text-white" style={{fontWeight:'bolder', fontSize:'17px'}}>Transaction History</h6>
</div>

<div className="setting-wrapper"><a className="setting-trigger-btn" id="settingTriggerBtn" href="#">
</a></div>
</div>
</div>
</div>


<div className="page-content-wrapper">

<div className="w-100">
<div className="card" style={{background:'#222250'}}>
<div className="card-body">
<div className="standard-tab">
    <ul className="nav rounded-lg mb-2 p-2 shadow-sm" id="affanTabs1" role="tablist">
    <li className="nav-item" role="presentation">
        <button className="btn active text-white" id="bootstrap-tab" data-bs-toggle="tab" data-bs-target="#bootstrap" type="button" role="tab" aria-controls="bootstrap" aria-selected="true">Buy/Sell</button>
    </li>
    <li className="nav-item" role="presentation">
        <button className="btn text-white" id="pwa-tab" data-bs-toggle="tab" data-bs-target="#pwa" type="button" role="tab" aria-controls="pwa" aria-selected="false">Deposit/Withdraw</button>
    </li>
    </ul>
    <div className="tab-content rounded-lg p-3 shadow-sm" id="affanTabs1Content">

    <div className="tab-pane fade show active" id="bootstrap" role="tabpanel" aria-labelledby="bootstrap-tab">
    {buyselld.length>0 ?
    <table className="table table-responsive text-white text-center gen_table" style={{height: '50px', overflowY: 'scroll'}}>
      <thead style={{fontSize:'10px'}}>
        <tr>
          <th>Date</th>
          <th>Transaction</th>
          <th>Asset</th>
          <th style={{textAlign:'right'}}>Amount</th>
        </tr>
      </thead>
      <tbody style={{fontSize:'9px'}}>
      {buyselld.map((item,index)=>(
          <tr>
          <td scope="row">{item.date}</td>
          <td>{item.type}</td>
          <td>{item.coin_code}</td>
          <td style={{textAlign:'right'}}>
          {parseFloat(item.left_qty)}
          </td>
        </tr>
      ))}
      </tbody>
    </table> :
      <p className="mb-0 text-center">No Data Found</p>
    }
    </div>


    {/* <!-- Deposite -->  */}
    <div className="tab-pane fade" id="pwa" role="tabpanel" aria-labelledby="pwa-tab">
    {depowithd.length>0 ?
    <table className="table table-responsive text-white text-center gen_table" style={{height: '50px', overflowY: 'scroll'}}>
    <thead style={{fontSize:'10px'}}>
        <tr>
          <th>Date</th>
          <th>Tranaction</th>
          <th>Asset</th>
          <th style={{textAlign:'right'}}>Amount</th>
        </tr>
      </thead>  
    <tbody style={{fontSize:'9px'}}>
    {depowithd.map((item,index)=>(
          <tr>
          <td scope="row">{new Date(item.created_at).toDateString()}</td>
          <td>{item.tran_type}</td>
          <td>{item.coin_name}</td>
          <td style={{textAlign:'right'}}>
          {parseFloat(item.amount)}
          </td>
        </tr>
      ))}
      </tbody>
    </table>
    :
    <p className="mb-0 text-center">No Data Found</p>
    }
    </div>

    </div>
</div>
</div>
</div>
</div>

</div>

    </motion.div>
  )
}
