import React, { useEffect } from 'react';

export default function Livegraphprice(props) {

  let coinpair = (props.coin+props.pair).toLowerCase();
  let ws = new WebSocket('wss://stream.binance.com:9443/ws/'+coinpair+'@ticker');
  let stockprice = document.getElementById('coin_prices');
  let lastprice = null;
  ws.onmessage = (event) => {
  let stockobject = JSON.parse(event.data);
  let price = parseFloat(stockobject.c);
  stockprice.innerHTML = price;
  stockprice.style.color = !lastprice || lastprice===price?'':price>lastprice?'green':'red';
  if(stockobject.P>0){
      document.getElementById('percent').innerHTML = '+'+parseFloat(stockobject.P).toFixed(2)+'%';
      document.getElementById('percent').style.color = 'green';
  }else{
      document.getElementById('percent').innerHTML = parseFloat(stockobject.P).toFixed(2)+'%';
      document.getElementById('percent').style.color = 'red';
  }
  lastprice = price;
  };

  return (
    <>

    </>
  )
}
