import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Authuser from '../../api/Authuser';
import { useNavigate } from 'react-router-dom';
import Gainerprice from './Gainerprice';

export default function Gainers() {
let canceltoken;
const {http,bUrl,murl} = Authuser();
const token = localStorage.getItem('token');
const navigate = useNavigate();
const [countrypair,setCountryPairs] = useState([]);
const [coinpair,setCoinPairs] = useState([]);
const [defaultpair,setDefaultpair] = useState('USDT');
const [gainercoins,setGainercoins] = useState([]);
useEffect(()=>{
    getcountrypairs();
    getcoinpairs();
    getgainercoins();
},[]); 

const getcountrypairs = () =>{
canceltoken = axios.CancelToken.source();   
http.get('/user-country-pair/'+token,{canceltoken:canceltoken.token}).then(res=>{
    setCountryPairs(res.data);
});
};

const getcoinpairs = () =>{
  canceltoken = axios.CancelToken.source();
http.get('/coin-pairs',{canceltoken:canceltoken.token}).then(res=>{
    setCoinPairs(res.data);
});
}

const pairchange = (e)=>{
setDefaultpair(e.target.value);
setGainercoins([]);
canceltoken = axios.CancelToken.source();
http.get('/gainers-coins/'+e.target.value,{canceltoken:canceltoken.token}).then(res=>{
    setGainercoins(res.data.data);
});
}

const getgainercoins = ()=>{
canceltoken = axios.CancelToken.source();
http.get('/gainers-coins/'+defaultpair,{canceltoken:canceltoken.token}).then(res=>{
    setGainercoins(res.data.data);
});
}

const graphpage = (id,pair) =>{
  navigate('/graph/'+id+'/'+pair);
  navigate(0);
}

  return (
    <>

<table className="table mb-0 table-striped" style={{marginTop: '-13px'}}>
<thead style={{fontSize:'13px', color:'#949494'}}>
<tr>
  <th scope="col" >
  <select style={{color:'#949494', border:'none'}} onChange={pairchange} >
    <option value={countrypair.pair_name} selected={countrypair.pair_name==defaultpair && 'selected'} >{countrypair.pair_name} Markets</option> 
    {coinpair.map((item,index)=>(
    <option value={item.pair_name} selected={item.pair_name==defaultpair && 'selected'} >{item.pair_name} Markets</option>    
    ))}
  </select>
  </th>
  <th scope="col" style={{textAlign:'right'}}>Last Price</th> 
  <th scope="col" style={{textAlign:'right'}}>24h Chg%</th> 
</tr>
</thead>
<tbody>


{gainercoins.map((item,index)=>(
  <tr style={{fontSize:'13px', color:'#000035', fontWeight:'bolder'}} key={item.id} onClick={() => graphpage(item.coin_code,defaultpair)} >
    <td>{item.coin_code} / {defaultpair}</td>
    <Gainerprice coin={item.coin_code} pair={defaultpair} unid={item.id} />
  </tr>
))}

</tbody>
</table>

    </>
  )
}
