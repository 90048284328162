import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation';
import Authuser from '../../api/Authuser';
import Pricedetails from './Pricedetails';

export default function Wallet() {
const {http,bUrl} = Authuser();
const navigate = useNavigate();
const token = localStorage.getItem('token'); 
const encodetoken = localStorage.getItem('encodeuserid'); 
const [countrydetails,setCountrydetails] = useState([]); 
const [totalpvalue,setTotalpvalue] = useState([]); 
const [countryvalue,setCountryvalue] = useState([]);
const [countrypairdetails,setCountrypairdetails] = useState([]); 
const [coins,setCoins] = useState([]); 
const [extracoins,setExtracoins] = useState([]); 
const [hasMore,setHasMore] = useState(true);
const [ddata,setDdata] = useState(300);
const [query,searchQuery] = useState("");
const [loading,setLoading] = useState('off');

useEffect(()=>{
    getwalletcoindetails();
    getusercountrydetails();
},[]);
const getwalletcoindetails = ()=>{
    setLoading('on');
    http.get('/wallet/'+token+'/'+ddata).then(res=>{
        setTotalpvalue(res.data.totalvalue);
        setCountryvalue(res.data.countryvalue);
        setCountrypairdetails(res.data.countrypair);
        setCoins(res.data.coins);
        setExtracoins(res.data.extracoin.data);
        setLoading('off');
    });
}
const getusercountrydetails = () =>{
    http.get('/user-country-details/'+token).then(res=>{
        setCountrydetails(res.data);
    });
}

const fetchmoreData = ()=>{
    let mdata = ddata + 20;
    setDdata(ddata+20);
    http.get('/wallet/'+token+'/'+mdata).then(res=>{
        setExtracoins(res.data.extracoin.data);
    });
}

const walletdetails = (coinid) =>{
    window.location.href = "https://maxpayz.tech/wallet/details/"+encodetoken+"/"+coinid;
}


  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >   

<div className="page-content-wrapper py-2">
<div className="container">
<div className="col-12 col-sm-12 col-md-12">
    <div className="card position-relative shadow-sm" style={{background:'#000066'}}>
        <div className='card-header'>
        <div className="input-group w-100">
            <button className="btn btn-dark" type="button"><i className="fa fa-search"></i></button>
            <input className="form-control form-control-clicked" onChange={(e)=>searchQuery(e.target.value)} id="txt_searchall" type="search" placeholder="Making Closer Digitally" style={{marginLeft: '-30px', height:'45px', background: '#05002D', border:'none', color:'white', borderBottom: '1px solid #0F243E', textAlign:'center'}} />
        </div>
        </div>
    <div className="card-body text-center">
        <h4 className="text-white">
        <img src={`${bUrl}storage/${countrydetails.symbol}`} style={{width:'30px', height:'20px'}} />
        {totalpvalue}
        </h4>
        <span className="text-white text-center">Total Protfolio Value</span>
    </div>
    </div>
</div>
</div>

<div className="notification-area">
{loading==='off' &&    
<div className="container" style={{marginBottom:'3px'}}>
<a href={`https://maxpayz.tech/fund/country-wallet-details/${encodetoken}/${countrypairdetails.id}`}>
    <div className="alert unread custom-alert-3 alert-primary" role="alert" style={{background:'#244062', border:'none'}}>
    &nbsp; &nbsp;
    <div className="alert-text w-75">
        <h6 className="text-truncate text-white" style={{marginLeft: '23px'}}>{countrypairdetails.currency_code}<br/><span className="text-white">{countrypairdetails.currency_name}</span></h6> 
        </div>
        <div className="alert-text w-50 text-right">
        <span className="text-white" style={{textAlign:'right', lineHeight:'32px', fontSize:'14px'}}>
        <img src={`${bUrl}storage/${countrydetails.symbol}`} style={{height:'15px'}} /> {countryvalue}
        </span>
        </div>
    </div></a> 
</div>
}

{/* <InfiniteScroll
    dataLength={extracoins.length}
    next={fetchmoreData}
    hasMore={hasMore}  
    style={{ marginTop: '-14px'}}
    > */}
<div className="container" id="fixed_content">

    {coins.filter((item)=>item.coin_code.toLowerCase().includes(query.toLowerCase())).map((item,index)=>(
    <a href="javascript:;" onClick={()=>walletdetails(item.coin_id)} key={item.id} >
    <div className="alert unread custom-alert-3 alert-primary" role="alert" style={{background:'#05002E', border:'none', height:'64px', marginTop: '-15px'}}>
    <Pricedetails coincode={item.coin_code} amount={item.amount} totalbuy={item.total_buy} incentive={item.total_incentive} wamount={item.withdraw_amount} totalsell={item.total_sell} />
    </div>
    </a> 
    ))}

    {extracoins.filter((item)=>item.coin_code.toLowerCase().includes(query.toLowerCase())).map((item,index)=>( 
    <a href="javascript:;" onClick={()=>walletdetails(item.id)} key={item.id}>
    <div className="alert unread custom-alert-3 alert-primary" role="alert" style={{background:'#05002E', border:'none', height:'64px', marginTop: '-15px'}}>
    <Pricedetails coincode={item.coin_code} amount={0} totalbuy={0} incentive={0} wamount={0} totalsell={0} /> 
    </div></a>
    ))}

</div>
{/* </InfiniteScroll> */}

</div>


</div>

    </motion.div>
  )
}
