import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation';
import Authuser from '../../api/Authuser';
import { Link } from 'react-router-dom';

export default function Banking() {

const {http,bUrl,murl,getToken} = Authuser();
const token = localStorage.getItem('token');
const [profiledetails,setProfiledetails] = useState([]);
const [iinput,setiInput] = useState();
const [input,setInput] = useState();
const [allbanks,setAllbanks] = useState([]);
const [allupi,setAllupi] = useState([]);

const [acno,setAcno] = useState([]);
const [reenteracno,setAcnoreenter] = useState([]);
const [ifsccode,setIfsccode] = useState([]);
const [actype,setActype] = useState([]);

useEffect(()=>{
    getprofiledetails();
    getallbanks();
    getallupis();
},[]); 

const getprofiledetails = () =>{
    http.get('/profile-details/'+token).then(res=>{
        setProfiledetails(res.data);
        console.log(res.data);
        if(profiledetails.bankverify==1 || profiledetails.bankverify==2){
        setInput('readonly');
        }
        if(profiledetails.upiverify==1 || profiledetails.upiverify==2){
        setiInput('readonly');
        }
    });
};

const getallbanks = () =>{
    http.get('/all-banks/'+token).then(res=>{
        setAllbanks(res.data);
        console.log(res.data);
    });
}

const getallupis = () =>{
    http.get('/all-upi/'+token).then(res=>{
        setAllupi(res.data);
        console.log(res.data);
    });
}
    
    
  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >

<div className="header-area" id="headerArea" style={{background:'#000035'}}>
<div className="container">

<div className="header-content position-relative d-flex align-items-center justify-content-between">

<div className="back-button"><Link to="/profile" className="text-white">
<svg className="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
</svg></Link></div>

<div className="page-heading">
<h6 className="mb-0 text-white" style={{fontWeight:'bolder', fontSize:'17px'}}>Bank & Upi</h6>
</div>

<div className="setting-wrapper"><a className="setting-trigger-btn" id="settingTriggerBtn" href="#">
</a></div>

</div>
</div>
</div>

<div className="page-content-wrapper py-3">
<div className="container">

<div className="card">
<div className="card-body">
<div className="accordion accordion-style-three" id="accordionStyle3">


<div className="accordion-item">
<div className="accordion-header" id="accordionSeven">
    <h6 className="shadow-sm rounded border collapsed" style={{fontSize:'18px', fontWeight:'bolder'}} data-bs-toggle="collapse" data-bs-target="#accordionStyleSeven" aria-expanded="false" aria-controls="accordionStyleSeven">
    Bank 
    {profiledetails.bankverify==2 &&
    <span className="badge bg-success">Verified</span>
    }
    {profiledetails.bankverify==1 &&
    <span className="badge bg-primary">Processing</span> 
    }
    {profiledetails.bankverify!=1 || profiledetails.bankverify!=2 &&
    <span className="badge bg-danger">Not Verified</span>
    }
    <svg className="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
    </svg>
    </h6>
</div>
<div className="accordion-collapse collapse" id="accordionStyleSeven" aria-labelledby="accordionSeven" data-bs-parent="#accordionStyle3" >
    <div className="accordion-body">
    
<form> 
<div className="form-group mb-3">
<label className="form-label" for="email">Choose Bank:</label>
<select className="form-control" readonly={input} >
    <option value="">-Select-</option>
    {allbanks.map((item,index)=>(
        <option value={item.bank_name} selected={profiledetails.bankname==item.bank_name && 'selected'} >{item.bank_name}</option>
    ))}
</select>

</div>
<div className="form-group mb-3">
<label className="form-label" >Account Number:</label>
<input className="form-control" type="text" onChange={e=>setAcno(e.target.value)} value={profiledetails.bankacno} readonly={input} />
</div> 
<div className="form-group mb-3">
<label className="form-label">Re Enter Account Number:</label>
<input className="form-control" type="text" onChange={e=>setAcnoreenter(e.target.value)} readonly={input} />
</div>
<div className="form-group mb-3">
<label className="form-label">IFSC Code:</label>
<input className="form-control" type="text" onChange={e=>setIfsccode(e.target.value)} value={profiledetails.bankifsccode} readonly={input} />
</div>
<div className="form-group mb-3">
<label className="form-label" for="email">Account Type:</label>
<select className="form-control" onChange={e=>setActype(e.target.value)} readonly={input}>
    <option value="">-Select-</option>
    <option value="Savings" selected={profiledetails.actype=='Savings' && 'selected'} >Savings</option>
    <option value="Current" selected={profiledetails.actype=='Current' && 'selected'} >Current</option>
</select>
</div>
{profiledetails.bankverify==0 || profiledetails.bankverify==3 &&
<button className="btn btn-primary w-100" type="submit">
    Submit
</button>
}
{profiledetails.bankverify==2 &&
<button className="btn btn-danger w-100" type="button">
    Remove
</button>
}
</form>

    </div>
</div>
</div>
<div className="divider border-primary"></div>


<div className="accordion-item">
<div className="accordion-header" id="accordionEight">
    <h6 className="shadow-sm rounded collapsed border" style={{fontSize:'18px', fontWeight:'bolder'}} data-bs-toggle="collapse" data-bs-target="#accordionStyleEight" aria-expanded="false" aria-controls="accordionStyleEight">
    UPI/VPA
    {profiledetails.upiverify==2 &&
    <span className="badge bg-success">Verified</span>
    }
    {profiledetails.upiverify==1 &&
    <span className="badge bg-primary">Processing</span> 
    }
    {profiledetails.upiverify!=1 || profiledetails.upiverify!=2 &&
    <span className="badge bg-danger">Not Verified</span>
    }
    <svg className="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
    </svg>
    </h6>
</div>
<div className="accordion-collapse collapse" id="accordionStyleEight" aria-labelledby="accordionEight" data-bs-parent="#accordionStyle3">
    <div className="accordion-body">
    
<form > 
<div className="form-group mb-3">
<label className="form-label" for="email">ALIAS:</label>
<select className="form-control" readonly={iinput} >
<option value="">-Select-</option>
{allupi.map((item,index)=>(
        <option value={item.upi_name} selected={profiledetails.upiname==item.upi_name && 'selected'} >{item.upi_name}</option>
))}
</select>
</div>

<div className="form-group mb-3">
<label className="form-label">UPI/VPA ID:</label>
<input className="form-control" type="text" value={profiledetails.upiid} readonly={iinput} />
</div>

{profiledetails.upiverify==0 || profiledetails.upiverify==3 &&
<button className="btn btn-primary w-100" type="submit">
Submit
</button>
}
{profiledetails.upiverify==2 &&
<button className="btn btn-danger w-100" type="button">
Remove
</button>
}
</form>

    </div>
</div>
</div>
<div className="divider border-primary"></div>

</div>
</div>
</div>


</div>
</div>

    </motion.div>
  )
}
