import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation';
import { Link } from 'react-router-dom';

export default function News() {
  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >

<div className="header-area" id="headerArea" style={{background:'#000035'}}>
<div className="container">

<div className="header-content position-relative d-flex align-items-center justify-content-between">

<div className="back-button"><Link to="/profile" className="text-white">
<svg className="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
</svg></Link></div>

<div className="page-heading">
<h6 className="mb-0 text-white" style={{fontWeight:'bolder', fontSize:'17px'}}>Maxpayz & News</h6>
</div>

<div className="setting-wrapper"><a className="setting-trigger-btn" id="settingTriggerBtn" href="#">
</a></div>
</div>
</div>
</div>

<div className="page-content-wrapper py-3">
<div className="container text-white">

<center><img src="/assets/images/maxpayz-logo.png" style={{width:'150px'}} /></center>
<ul style={{fontSize:'13px', marginTop:'5px'}}>
    <li>
        Maxpayz the simple, safe way to trade, store,stack, buy
        and sell your cryptocurrency.
    </li>
    <li>
        Get matched with verified buyers & sellers directly.
    </li>
    <li>
        400+ markets with multiples pairs.
    </li>
    <li>
        We protect your fund very seriously.
    </li>
</ul><br/>

<center><h3 style={{color:'#FFFF00', fontWeight:'bolder', marginTop:'-10px'}}>NEWS</h3></center>
<ul style={{fontSize:'13px', marginTop:'7px'}}>
    <li>
        Reffer and get 60% incentive on trade fees up to five
        generation.
    </li>
    <li>
        Trade crypto and win reward.
    </li>
    <li>
        Swell your love by using maxpayz gift card.
    </li>
    <li>
        Try maxpayz P2P option for smoothness of the
        transaction.
    </li>
</ul><br/>

<center>
    <h6 style={{color:'white', fontWeight:'bolder', marginTop:'-10px'}}>Powerd By </h6>
    <img src="/assets/images/kolam-logo.png" style={{width:'150px'}} /><br/><br/>
    <img src="/assets/images/news_b3.png" style={{width:'90%'}} /><br/>
    <img src="/assets/images/madeinindia.png" style={{width:'70%', right:'50px', position:'fixed', bottom:'7px'}} />
</center>

</div>
</div>

    </motion.div>
  )
}
