import React, { useEffect, useState } from 'react';
import Authuser from '../../api/Authuser';

export default function Userdetailsbyid(props) {

const {http} = Authuser();
const [userdetails,setUserdetails] = useState([]);
useEffect(()=>{
    getuserdetails();
},[]); 

const getuserdetails = () =>{
    http.get('/user-details-by-id/'+props.userid).then(res=>{
        setUserdetails(res.data);
        console.log(res.data);
    });
};

  return (
    <>

<td style={{textAlign:'right'}}>{userdetails.email}</td>


    </>
  )
}
