import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { animationone } from '../../animations/Animation';
import Authuser from '../../api/Authuser';
import { useNavigate, useParams } from 'react-router-dom';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import Livegraphprice from './Livegraphprice';

export default function Graph() {

  const {coincode} = useParams();
  const {pair} = useParams();
  const {http,bUrl} = Authuser();
  const token = localStorage.getItem('token');
  const encodetoken = localStorage.getItem('encodeuserid'); 
  const navigate = useNavigate();
  const [singlecoinpair,setSingleCoinpair] = useState([]);
  const [countrydetails,setCountrydetails] = useState([]);
  const [coinpairprice,setCoinpairprice] = useState([]);
  const [alltrades,setAlltrades] = useState([]);
  const [forbuy,setForbuy] = useState([]);
  const [forsell,setForsell] = useState([]);
  const [customcoin,setCustomcoin] = useState();

  useEffect(()=>{
    getusercountrydetails();
    getpaircoin();
    getordertrades();
    if(coincode==='ECOIN'){
      setCustomcoin('NKNUSDT');
    }
    if(coincode==='GBCOIN'){
      setCustomcoin('WINGUSDT');
    }
    if(coincode==='WINDSOR'){
      setCustomcoin('HARDUSDT');
    }
    if(coincode==='BTPS'){
      setCustomcoin('EOSUSDT');
    }
  },[]);

const getusercountrydetails = () =>{
  http.get('/user-country-details/'+token).then(res=>{
    setCountrydetails(res.data);
  });
}
const getpaircoin = () =>{
  http.post('/single-coin-pair/',{pair:pair,coincode:coincode}).then(res=>{
    setSingleCoinpair(res.data);
  });
  http.get('/coin-pair-price/'+pair).then(res=>{
    setCoinpairprice(res.data);
  });
};
const getordertrades = () =>{
  http.get('/order-trade-details/'+coincode+'/'+pair).then(res=>{
    setAlltrades(res.data.alltrades.data);
    setForbuy(res.data.forbuy.data);
    setForsell(res.data.forsell.data);
});
}

const buysellpage = (tradetype) =>{
  // navigate('/buysell/'+coincode+'/'+pair+'/'+tradetype);
  if(tradetype==='Buy'){
  window.location.href = "https://maxpayz.tech/buy/"+encodetoken+"/"+singlecoinpair.coinid+"/"+pair;
  }else{
  window.location.href = "https://maxpayz.tech/sell/"+encodetoken+"/"+singlecoinpair.coinid+"/"+pair;
  }
}

  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >  

<Helmet>
    <title>Maxpayz | Market</title>
    <link rel="stylesheet" href="/assets/css/graphpage.css" />
</Helmet>  

<div className="header-area" id="headerArea" style={{background:'white'}}>
<div className="container">
    
<div className="header-content position-relative d-flex align-items-center justify-content-between">

<div className="back-button"><a href="javascript:history.go(-1)" className=""> 
<svg className="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
</svg></a></div>

<div className="page-heading">
<h6 className="mb-0" style={{fontWeight:'bolder', fontSize:'18px'}}>
{coincode}/{pair}
</h6>
</div>

<div className="setting-wrapper">
<ul className="h-100 d-flex align-items-center justify-content-between ps-0">

<li>
    <a href="javascript:;" onClick={()=>buysellpage('Buy')} className="btn btn-success btn-sm" style={{height: '32px',lineHeight: '23px', fontSize: '14px', fontWeight:'bolder', margin:'3px'}}>
    Buy {coincode}
    </a>
</li>
<li>
    <a href="javascript:;" onClick={()=>buysellpage('Sell')} className="btn btn-danger btn-sm" style={{height: '32px',lineHeight: '23px', fontSize: '14px', fontWeight:'bolder', marginRight:'-6px'}}>
    Sell {coincode}
    </a>
</li>

</ul>
</div>
</div>
</div>
</div>


<div className="page-content-wrapper" style={{background:'white', borderTop:'1px solid black'}}>
<div className="container" style={{background:'white'}}>
<div className="row">
<div className="col-5">
    <h3 className="text-center" style={{marginTop: '5px'}}>
    {singlecoinpair.pairtype==='Country' ?
    <span id="coin_prices" style={{fontSize:'18px', fontWeight:'bolder'}}>
      {parseFloat(singlecoinpair.price*countrydetails.plusvalue)}
    </span>
    :
    <span id="coin_prices" style={{fontSize:'18px', fontWeight:'bolder'}}>
      {parseFloat(singlecoinpair.price)}
    </span>
    }
    <br/>
    <span><img src={`${bUrl}storage/${countrydetails.colorsymbol}`} style={{width:'15px', height:'10px', marginTop: '6px', marginRight: '-4px'}} /></span>&nbsp;
    {singlecoinpair.pairtype==='Country' ? 
    <span style={{fontWeight:'bolder', fontSize:'11px', marginRight: '0px'}} id="cc_price">
    {(singlecoinpair.price*countrydetails.plusvalue).toFixed(2)}
    </span>
    :
    pair==='USDT' ?
    <span style={{fontWeight:'bolder', fontSize:'11px', marginRight: '0px'}} id="cc_price">
    {(singlecoinpair.price*countrydetails.normalvalue).toFixed(2)}
    </span> :
    <span style={{fontWeight:'bolder', fontSize:'11px', marginRight: '0px'}} id="cc_price">
    {(singlecoinpair.price*countrydetails.normalvalue*coinpairprice).toFixed(2)}
    </span>
    }
    &nbsp;
    {singlecoinpair.percentage>=0 ?
    <span id="percent" style={{fontSize:'10px', color:'green', marginLeft: '0px'}}>{singlecoinpair.percentage}%</span>
    :
    <span id="percent" style={{fontSize:'10px', color:'red', marginLeft: '0px'}}>{singlecoinpair.percentage}%</span>
    }
    </h3>
</div>
<div className="col-4">
    <h6 style={{marginTop: '5px', textAlign:'right'}} >
    <span style={{fontSize:'13px'}}>24h High: </span><br/>
    <span style={{fontSize:'13px'}}>24h Low: </span><br/>
    <span style={{fontSize:'13px'}}>24h Volume: </span>
    </h6>
</div>
<div className="col-3">
    <h6 style={{marginTop: '5px', textAlign:'right'}} >
    <span id="high" style={{fontSize:'13px'}}>{parseFloat(singlecoinpair.high)}</span><br/> 
    <span id="low" style={{fontSize:'13px'}}>{parseFloat(singlecoinpair.low)}</span><br/>
    <span style={{fontSize:'13px'}}>********</span>
    </h6>
</div>
</div>
</div>

<Livegraphprice coin={coincode} pair={pair} />

<div className="w-100" style={{background:'white'}}>
{coincode==='USDT' ?
coincode==='USDT' ?
<div id="watchlist-chart-demo">
  <TradingViewWidget
    symbol="BUSDUSDT"
    theme={Themes.light}
    width="100%"
    height="100%"
    timezone="Asia/Kolkata"
    style="1"
    toolbar_bg="#f1f3f6"
    withdateranges="true"
    locale="in"
    autosize
  />
  </div>
:
<center style={{height:'400px', lineHeight: '250px'}}>Graph Currently not available</center> 
:
<div className="tradingview-widget-container">
  <span></span> 
  {singlecoinpair.pairtype==='Coin' ? 
  coincode=='GBCOIN' || coincode==='ECOIN' || coincode==='WINDSOR' || coincode==='BTPS'?
  <div id="watchlist-chart-demo">
  <TradingViewWidget
    symbol={customcoin}
    theme={Themes.light}
    width="100%"
    height="100%"
    timezone="Asia/Kolkata"
    style="1"
    toolbar_bg="#f1f3f6"
    withdateranges="true"
    hide_legend= "true"
    allow_symbol_change={false}
    locale="in"
    autosize
  />
  
  </div>
  :
  <div id="watchlist-chart-demo">
  <TradingViewWidget
    symbol={`${coincode}${pair}`}
    theme={Themes.light}
    width="100%"
    height="100%"
    timezone="Asia/Kolkata"
    style="1"
    toolbar_bg="#f1f3f6"
    withdateranges="true"
    locale="in"
    autosize
  />
  </div>
  :
  coincode==='GBCOIN' || coincode==='ECOIN' || coincode==='WINDSOR' || coincode==='BTPS' ?
  <div id="watchlist-chart-demo">
  <TradingViewWidget
    symbol={customcoin}
    theme={Themes.light}
    width="100%"
    height="100%"
    timezone="Asia/Kolkata"
    style="1"
    toolbar_bg="#f1f3f6"
    withdateranges="true"
    hide_legend= "true"
    allow_symbol_change={false}
    locale="in"
    autosize
  />
  
  </div>
  :
  <div id="watchlist-chart-demo">
  <TradingViewWidget
    symbol={`${coincode}USDT`}
    theme={Themes.light}
    width="100%"
    height="100%"
    timezone="Asia/Kolkata"
    style="1"
    toolbar_bg="#f1f3f6"
    withdateranges="true"
    locale="in"
    autosize
  />
  </div>
    } 
</div>
}  

</div>
</div>

<div className="add-new-contact-wrap text-center"><img src="/assets/images/watermark1.png" /></div>


<div className="w-100" style={{marginTop: '-61px'}}>
<div className="card">
<div className="card-body">
<div className="standard-tab" style={{textAlign: 'center'}}>
    
<ul className="nav  mb-1 p-1 shadow-sm" id="affanTabs1" role="tablist">
<li className="nav-item" role="presentation">
    <span className="active" id="order-tab" data-bs-toggle="tab" data-bs-target="#order" type="button" role="tab" aria-controls="dark" aria-selected="false">Order Book</span>
</li>
<li className="nav-item" role="presentation">
    <span className="" id="trade-tab" data-bs-toggle="tab" data-bs-target="#trade" type="button" role="tab" aria-controls="dark" aria-selected="false">Trades</span>
</li>
</ul>
<div className="tab-content rounded-lg p-1 shadow-sm" id="affanTabs1Content">

{/* <!--Trades--> */}
<div className="tab-pane fade" id="trade" role="tabpanel" aria-labelledby="trade-tab">
<table className="table mb-0 table-striped" style={{marginLeft: '-2px'}}>
  <thead style={{color:'black'}}>
    <tr>
      <th scope="col" style={{textAlign:'left', fontSize: '13px'}}>Time</th>
      <th scope="col" style={{textAlign:'right', fontSize: '13px'}}>Price</th>
      <th scope="col" style={{textAlign:'right', fontSize: '13px'}}>Amount</th>
    </tr>
  </thead>
  <tbody>
  
  {alltrades.map((item,index)=>( 
    <tr>
    <td style={{textAlign:'left', fontSize: '13px'}}>{new Date(item.updated_at).toDateString()}</td>
      <td style={{textAlign:'right', fontSize: '13px'}}>{parseFloat(item.at_price)}</td>
      {(item.qty-item.left_qty)===0 ?
      <td style={{textAlign:'right', fontSize: '13px'}}>{item.qty}</td> :
      <td style={{textAlign:'right', fontSize: '13px'}}>{item.qty-item.left_qty}</td>
      }
    </tr>
  ))}
  </tbody>
</table>
</div>


{/* <!--order book--> */}
<div className="tab-pane show active" id="order" role="tabpanel" aria-labelledby="order-tab">
    
<div className="row">
<div className="col-6" style={{borderRight:'2px solid #747285', marginTop:'-5px'}}>
<table className="table mb-0 table-striped" style={{marginLeft: '2px'}}>
<thead style={{color:'black'}}>
<tr>
  <th scope="col" style={{textAlign:'left', fontSize: '13px'}}>Bid</th>
  <th scope="col" style={{textAlign:'right', fontSize: '13px'}}>Buy Price</th>
</tr>
</thead>
<tbody>
{forbuy.map((item,index)=>( 
<tr style={{color:'green'}}>
  <td style={{textAlign:'left', fontSize: '13px'}}>{parseFloat(item.left_qty)}</td> 
  <td style={{textAlign:'right', fontSize: '13px'}}>{parseFloat(item.at_price)}</td> 
</tr>
))}
</tbody>
</table>
</div>

<div className="col-6" style={{marginTop:'-5px'}}>
<table className="table mb-0 table-striped" style={{marginLeft: '-2px'}}>
  <thead style={{color:'black'}}>
    <tr>
      <th scope="col" style={{textAlign:'left', fontSize: '13px'}}>Sell Price</th>
      <th scope="col" style={{textAlign:'right', fontSize: '13px'}}>Bid</th>
    </tr>
  </thead>
  <tbody>
  {forsell.map((item,index)=>( 
    <tr style={{color:'red'}}>
      <td style={{textAlign:'left', fontSize: '13px'}}>{parseFloat(item.at_price)}</td>
      <td style={{textAlign:'right', fontSize: '13px'}}>{parseFloat(item.left_qty)}</td>
    </tr>
))}
  </tbody>
</table>
</div>
</div>

</div>
{/* <!--Order Book End--> */}

</div>
</div>
</div>

</div>
</div><br/><br/><br/><br/><br/><br/><br/><br/>


{/* <div className="footer-nav-area" id="footerNav" style={{marginBottom:'48px'}}>
  <div className="container px-0">
    <div className="footer-nav position-relative">
      
    </div>
  </div>
</div> */}


    </motion.div>
  )
}
