import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation';
import {Helmet} from "react-helmet"; 
import Authuser from '../../api/Authuser';

export default function Profile() {

const {http,bUrl,murl,getToken,logout} = Authuser();
const token = localStorage.getItem('token');
const [profiledetails,setProfiledetails] = useState([]);
const [loader,setLoader] = useState('off');

useEffect(()=>{
    generatecustid();
    getprofiledetails();
},[]); 

const generatecustid = () =>{
http.get('/generate-cust-id/'+token).then(res=>{
});
}

const getprofiledetails = () =>{
    setLoader('on');
    http.get('/profile-details/'+token).then(res=>{
        setProfiledetails(res.data);
        setLoader('off');
    });
};

const logoutUser = () =>{
    if(getToken()){
      logout();
    }
  } 


  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >

     <div className="page-content-wrapper py-3" >
      <div className="container">

        <div className="card user-info-card mb-3" style={{background:'#05002E'}}>
          {loader==='on' &&
          <center><span>Loading...</span></center>
          }
          {loader==='off' &&
          <div className="card-body d-flex align-items-center">
            <div className="user-profile me-3">
                {profiledetails.profileveri!=2 ?
                <img src="/assets/img/bg-img/mprofile.jpg" alt=""/>
                :
                <img src={`${murl}${profiledetails.profilepic}`} />
                }
            </div>
            <div className="user-info">
              <div className="d-flex mb-1 align-items-center">
                <h6 className="mb-1 text-white">{profiledetails.email}</h6>
              </div>
              <p className="mb-0 text-white">Cust ID: {profiledetails.custid}</p>
              <p className="mb-0 text-white">{profiledetails.isdcode} {profiledetails.phone}</p>
            </div>
            </div>
          }
        </div> 


        <div className="row g-3">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="card position-relative shadow-sm" style={{background:'#05002E'}}>
                {loader==='on' &&
                <center><span>Loading...</span></center>
                }
                {loader==='off' &&
                <div className="card-body text-center">
                  {profiledetails.kycveri==2 && profiledetails.kycaddressveri==2 && profiledetails.profileveri==2  ?
                    <span className="badge bg-success rounded-pill mb-2 d-inline-block">Verified</span> 
                    :
                    <span className="badge bg-danger rounded-pill mb-2 d-inline-block">Not Verified</span>
                    }
                  <a className="blog-title d-block text-white" href={`https://maxpayz.tech/profile/kyc/${token}`}>
                    Update Your KYC
                  </a>
                  </div>
                }
              </div>
            </div>

            {/* <!-- Others --> */}
            <div className="col-6 col-sm-4 col-md-3">
              <div className="card position-relative shadow-sm" style={{background:'#05002E'}}>
                <div className="card-body text-center">
                  <a className="blog-title d-block mb-3 text-white" style={{lineHeight:'60px'}} href={`https://maxpayz.tech/profile/bank-payment/${token}`}>
                    Banking & Payment
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3">
              <div className="card position-relative shadow-sm" style={{background:'#05002E'}}>
                <div className="card-body text-center">
                  <Link className="blog-title d-block mb-3 text-white" style={{lineHeight:'60px'}} to="/reffer-earn">
                    Reffer & Earn
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3">
              <div className="card position-relative shadow-sm" style={{background:'#05002E'}}>
                <div className="card-body text-center">
                  <Link className="blog-title d-block mb-3 text-white" style={{lineHeight:'60px'}} to="/transaction-history">
                    Transaction History
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3">
              <div className="card position-relative shadow-sm" style={{background:'#05002E'}}>
                <div className="card-body text-center">
                  <Link className="blog-title d-block mb-3 text-white" style={{lineHeight:'60px'}} to="/news">
                    Maxpayz & News
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="card position-relative shadow-sm" style={{background:'#05002E'}}>
                <div className="card-body text-center">
                  <a className="blog-title d-block mb-3 text-white" style={{lineHeight:'60px'}} href="#">
                    Help & Support
                  </a>
                  <span className="profile_icons">
                    <a href="#"><i className="fa fa-twitter-square fa-2x" aria-hidden="true"></i></a>
                    <a href="#"><i className="fa fa-telegram fa-2x" aria-hidden="true"></i></a>
                    <a href="#"><i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>
                    <a href="#"><i className="fa fa-instagram fa-2x" aria-hidden="true"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <center><button type='button' onClick={logoutUser} className="btn btn-info">Logout</button></center>
        </div>

</div> 
</div>    

    </motion.div>
  )
}
