import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation';
import Authuser from '../../api/Authuser';
import { Link } from 'react-router-dom';

export default function Kycverification() {

const {http,bUrl,murl,getToken} = Authuser();
const token = localStorage.getItem('token');
const [profiledetails,setProfiledetails] = useState([]);
const [inputd,setInput] = useState();
const [iinput,setiInput] = useState();
const [pinput,setpInput] = useState();
const [aloader,setaLoader] = useState('off');
const [iloader,setiLoader] = useState('off');
const [ploader,setpLoader] = useState('off');

const [caddress,setAddress] = useState([]);
const [caddressproof,setAddressproof] = useState([]);
const [cdocumentno,setDocumentno] = useState([]);
const [cdocumentimg,setDocumentimg] = useState([]);

const [identityproof,setIdentityproof] = useState([]);
const [idocumentno,setIdentitydocumentno] = useState([]);
const [idocumentimg,setIdentitydocumentimg] = useState([]);

const [profileimg,setprofileimg] = useState([]);

useEffect(()=>{
    getprofiledetails();
},[]); 

const getprofiledetails = () =>{
    http.get('/profile-details/'+token).then(res=>{
        setProfiledetails(res.data);
        if(profiledetails.kycaddressveri==1 || profiledetails.kycaddressveri==2){
        setInput('readonly');
        }
        if(profiledetails.kycveri==1 || profiledetails.kycveri==2){
        setiInput('readonly');
        }
        if(profiledetails.profileveri==1 || profiledetails.profileveri==2){
        setiInput('readonly');
        }
    });
};

const kycaddresssubmitForm = () =>{
    setaLoader('on');
    const data = new FormData();
    data.append('document_image', cdocumentimg);
    data.append('address', caddress); 
    data.append('address_proof', caddressproof); 
    data.append('document_no', cdocumentno);
    data.append('id', token); 
    http.post('/profile-details', data).then(res=>{
        console.log(res.data);
    });
}

const kycidentitysubmitForm = () =>{

}

const kycprofilesubmitForm = () =>{

}


  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >


<div className="header-area" id="headerArea" style={{background:'#000035'}}>
<div className="container">

<div className="header-content position-relative d-flex align-items-center justify-content-between">

<div className="back-button"><Link to="/profile" className="text-white">
<svg className="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
</svg></Link></div>

<div className="page-heading">
<h6 className="mb-0 text-white" style={{fontWeight:'bolder', fontSize:'17px'}}>KYC Update</h6>
</div>

<div className="setting-wrapper"><a className="setting-trigger-btn" id="settingTriggerBtn" href="#">
</a></div>

</div>
</div>
</div>

<div className="page-content-wrapper py-3">
<div className="container">

<div className="card">
<div className="card-body">
<div className="accordion accordion-style-three" id="accordionStyle3">

<div className="accordion-item">
<div className="accordion-header" id="accordionSeven">
    <h6 className="shadow-sm rounded border collapsed" style={{fontSize:'18px', fontWeight:'bolder'}} data-bs-toggle="collapse" data-bs-target="#accordionStyleSeven" aria-expanded="false" aria-controls="accordionStyleSeven">
    Address 
    {profiledetails.kycaddressveri==2 &&
    <span className="badge bg-success">Verified</span>
    }
    {profiledetails.kycaddressveri==1 &&
    <span className="badge bg-primary">Processing</span> 
    }
    {profiledetails.kycaddressveri!=1 || profiledetails.kycaddressveri!=2 &&
    <span className="badge bg-danger">Not Verified</span>
    }
    <svg className="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
    </svg>
    </h6>
</div>
<div className="accordion-collapse collapse" id="accordionStyleSeven" aria-labelledby="accordionSeven" data-bs-parent="#accordionStyle3">
    <div className="accordion-body">
    
    {/* Address Verification */}
    <form enctype="multipart/form-data">
    <div className="form-group mb-3">
    <label className="form-label" for="Username">Name:</label>
    <input className="form-control" id="Username" type="text" value={profiledetails.name} disabled />
    </div>
    <div className="form-group mb-3">
    <label className="form-label" for="fullname">E-Mail:</label>
    <input className="form-control" id="fullname" type="text" value={profiledetails.email} disabled />
    </div>
    <div className="form-group mb-3">
    <label className="form-label" for="email">Phone:</label>
    <input className="form-control" type="text" value={profiledetails.isdcode+' '+profiledetails.phone} disabled />
    </div>
    <div className="form-group mb-3">
    <label className="form-label" for="email">Country:</label>
    <input className="form-control" type="text" value={profiledetails.countryname} disabled />
    </div>
     <div className="form-group mb-3">
    <label className="form-label" for="email">Address:</label>
    <textarea className="form-control" onChange={e=>setAddress(e.target.value)} value={profiledetails.address} readonly={inputd} ></textarea>
    </div>
    <div className="form-group mb-3">
    <label className="form-label" for="email">Upload Address Proof:</label>
    <select className="form-control" onChange={e=>setAddressproof(e.target.value)} readonly={inputd} >
        <option value="">-Select-</option>
        <option value="Aadhaar Card" selected={profiledetails.addressprname=='Aadhaar Card' && 'selected'} >Aadhaar Card</option>
        <option value="Passport" selected={profiledetails.addressprname=='Passport' && 'selected'}>Passport</option>
        <option value="Election Card" selected={profiledetails.addressprname=='Election Card' && 'selected'}>Election Card</option>
        <option value="National Id Card" selected={profiledetails.addressprname=='National Id Card' && 'selected'}>National Id Card</option>
    </select>
    </div>
    <div className="form-group mb-3">
    <label className="form-label" for="email">Document No.:</label>
    <input className="form-control" type="text" onChange={e=>setDocumentno(e.target.value)} value={profiledetails.addressdocno} readonly={inputd} />
    </div>
    <div className="form-group mb-3">
    <label className="form-label" for="job">Upload Document Image:</label>
    <input className="form-control" type="file" onChange={e=>setDocumentimg(e.target.files[0])} readonly={inputd} />
    {profiledetails.addressdocimage!='' && 
    <img src={`${murl}storage/${profiledetails.addressdocimage}`} style={{width:'50px', height:'50px'}} />
    }
    </div>

    {profiledetails.kycaddressveri==0 || profiledetails.kycaddressveri==3 &&
    <button className="btn btn-primary w-100" type="button" onClick={kycaddresssubmitForm}>
    {aloader=='off' &&
    <span>Submit</span>
    }
    {aloader=='on' &&
    <center><span className="spinner-border spinner-border-sm"></span></center>
    }
    </button>
    }

    </form>
    {/* End Address Verification */}

    </div>
</div>
</div>
<div className="divider border-primary"></div>


<div className="accordion-item">
<div className="accordion-header" id="accordionEight">
    <h6 className="shadow-sm rounded collapsed border" style={{fontSize:'18px', fontWeight:'bolder'}} data-bs-toggle="collapse" data-bs-target="#accordionStyleEight" aria-expanded="false" aria-controls="accordionStyleEight">
    Identity
    {profiledetails.kycveri==2 &&
    <span className="badge bg-success">Verified</span>
    }
    {profiledetails.kycveri==1 &&
    <span className="badge bg-primary">Processing</span> 
    }
    {profiledetails.kycveri!=1 || profiledetails.kycveri!=2 &&
    <span className="badge bg-danger">Not Verified</span>
    }
    
    <svg className="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
    </svg>
    </h6>
</div>
<div className="accordion-collapse collapse" id="accordionStyleEight" aria-labelledby="accordionEight" data-bs-parent="#accordionStyle3">
    <div className="accordion-body">
    {/* <livewire:app-panel.kyc-update.identity-component /> */}
<form enctype="multipart/form-data">
<div className="form-group mb-3">
<label className="form-label">Upload Identity Proof:</label>
<select className="form-control" onChange={e=>setIdentityproof(e.target.value)} readonly={iinput} >
<option value="">Select</option>
<option value="Pan Card" selected={profiledetails.identityprname=='Pan Card' && 'selected'} >Pan Card</option>
<option value="Driving License" selected={profiledetails.identityprname=='Driving License' && 'selected'} >Driving License</option>
<option value="National Id Card" selected={profiledetails.identityprname=='National Id Card' && 'selected'} >National Id Card</option>
</select>
</div>

<div className="form-group mb-3">
<label className="form-label">Document No.:</label>
<input className="form-control" type="text" onChange={e=>setIdentitydocumentno(e.target.value)} value={profiledetails.identitydocno} readonly={iinput} />
</div>

<div className="form-group mb-3">
<label className="form-label">Upload Document Image</label>
<input className="form-control" type="file" onChange={e=>setIdentitydocumentimg(e.target.files[0])} readonly={iinput} />
{profiledetails.identitydocimage!='' && 
    <img src={`${murl}storage/${profiledetails.identitydocimage}`} style={{width:'50px', height:'50px'}} />
}
</div>

{profiledetails.kycveri==0 || profiledetails.kycveri==3 &&
<button className="btn btn-primary w-100" type="button" onClick={kycidentitysubmitForm}>
{iloader=='off' &&
<span>Submit</span>
}
{iloader=='on' &&
<center><span className="spinner-border spinner-border-sm"></span></center>
}
</button>
}
</form>

    </div>
</div>
</div>
<div className="divider border-primary"></div>

<div className="accordion-item">
<div className="accordion-header" id="accordionNine">
    <h6 className="shadow-sm rounded collapsed border" style={{fontSize:'18px', fontWeight:'bolder'}} data-bs-toggle="collapse" data-bs-target="#accordionStyleNine" aria-expanded="false" aria-controls="accordionStyleNine">
        Take a photo
    {profiledetails.profileveri==2 &&
    <span className="badge bg-success">Verified</span>
    }
    {profiledetails.profileveri==1 &&
    <span className="badge bg-primary">Processing</span> 
    }
    {profiledetails.profileveri!=1 || profiledetails.profileveri!=2 &&
    <span className="badge bg-danger">Not Verified</span>
    }
    <svg className="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
    </svg>
    </h6>
</div>
<div className="accordion-collapse collapse" id="accordionStyleNine" aria-labelledby="accordionNine" data-bs-parent="#accordionStyle3">
    <div className="accordion-body">
    {/* <livewire:app-panel.kyc-update.profilepic-component /> */}

<form enctype="multipart/form-data">
<div className="form-group mb-3">
<label className="form-label">Upload Self Picture</label>
<input className="form-control" type="file" onChange={e=>setprofileimg(e.target.files[0])} readonly={pinput} />
{profiledetails.profilepic!='' && 
<img src={`${murl}storage/${profiledetails.profilepic}`} style={{width:'50px', height:'50px'}} />
}
</div>

{profiledetails.profileveri==0 || profiledetails.profileveri==3 &&
<button className="btn btn-primary w-100" type="button" onClick={kycprofilesubmitForm}>
{ploader=='off' &&
<span>Submit</span>
}
{ploader=='on' &&
<center><span className="spinner-border spinner-border-sm"></span></center>
}
</button>
}
</form>

    </div>
</div>
</div>

</div>
</div>
</div>


</div>
</div>



    </motion.div>
  )
}
