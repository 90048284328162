
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation';
import Authuser from '../../api/Authuser';

export default function Intro() { 

  const navigate = useNavigate();
  const {getToken} = Authuser();

  useEffect(()=>{
    if(getToken()){
        navigate('/dashboard');
    }
});

  return (
    <motion.div transition={{ type:'tween', duration:0.3 }} initial="out" animate="end" exit="out" variants={animationone} >

    <div className="internet-connection-status" id="internetStatus"></div>

    <div className="hero-block-wrapper" style={{background:'#000035'}}>
      <div className="hero-block-styles">
        <div className="hb-styles1" style={{backgroundImage: `url(require("/assets/img/core-img/dot.png"))` }}></div>
        <div className="hb-styles3"></div>
      </div>

      <div className="skip-page"><Link to="/dashboard">Skip</Link></div>

      <div className="hero-block-content" style={{background:'#000035'}}>
        <center>
        <img className="mb-4" src="/assets/images/logo.png" style={{width:'250px'}} alt=""/>
        <img className="mb-4" src="/assets/images/intro.png" alt=""/>
        <h2 className="display-4 text-white mb-3" style={{fontSize:'19px'}}>Trade Crypto on Maxpayz</h2>
        <p className="text-white">Get matched with verified buyers & sellers directly</p>
        <Link className="btn btn-primary btn-lg w-100" to="/login">Get Started</Link>
        </center>
      </div>
    </div>
    
    </motion.div>
  )
}
