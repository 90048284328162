import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Authuser from '../../api/Authuser';
// import Livehotvol from './Livehotvol';

export default function Loserprice(props) {
let canceltoken;
const {http} = Authuser();
const [loserscoins,setLoserscoins] = useState([]);
useEffect(()=>{
    getloserscoinprice();
},[]);

const getloserscoinprice = () =>{ 
canceltoken = axios.CancelToken.source();   
http.get('/losers-coin-price/'+props.pair+'/'+props.coin,{canceltoken:canceltoken.token}).then(res=>{
    setLoserscoins(res.data);
});    
}    

  return (
    <>

<td style={{textAlign:'right'}} id={`coin_prices${props.unid}`} >{parseFloat(loserscoins.coinprice)}</td>
<td style={{textAlign:'right'}}>
{loserscoins.percentage>=0 &&
    <span className="badge" style={{background:'green'}} id={`percent${props.unid}`} >{loserscoins.percentage}</span>  
}
{loserscoins.percentage<0 &&
    <span className="badge" style={{background:'red'}} id={`percent${props.unid}`} >{loserscoins.percentage}</span>  
}
</td>
{/* <Livehotvol coin={props.coin} pair={props.pair} uniid={props.unid} /> */}

    </>
  )
}
