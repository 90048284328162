import Authuser from './api/Authuser';
import Auth from './layouts/Auth';
import Guest from './layouts/Guest';

function App() {

  const {getToken} = Authuser();
  if(!getToken()){
    return <Guest /> 
  }

  return (
    <>
      <Auth />
    </>
  );

}

export default App;
