import React, { useEffect,useState }  from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation';
import Authuser from '../../api/Authuser';

export default function Register() {

  const navigate = useNavigate();
  const {getToken} = Authuser();

  const {http,setToken} = Authuser();
  const [email,setEmail] = useState();
  const [erroremail,setErroremail] = useState();
  const [password,setPassword] = useState();
  const [errorpass,setErrorpass] = useState();
  const [loader,setLoader] = useState('off');
  const [errormsg,setErrormsg] = useState(null);

  useEffect(()=>{
    if(getToken()){
        navigate('/dashboard');
    }
});

const submitForm = () => {
  setLoader('on');
  http.post('/login',{email:email,password:password}).then(res=>{
    if(res.data.status==422){
      setErroremail(res.data.error.email);
      setErrorpass(res.data.error.password);
    }else if(res.data.status==300){
      setErrormsg(res.data.errormsg);
    }else{
      setToken(res.data.user.id,res.data.flag);
    }
    setLoader('off');
  });
}

  return (
    <motion.div transition={{ type:'tween', duration:0.3 }} initial="out" animate="end" exit="out" variants={animationone} >

<div className="login-wrapper d-flex align-items-center justify-content-center" style={{background:'#000035'}}>
  <div className="custom-container">
    <div className="text-center px-4" style={{marginTop:'-50px', marginBottom:'30px'}}>
        <img className="login-intro-img" src="/assets/images/logo.png" style={{width:'250px'}} alt=""/>
    </div>
    
<div className="register-form mt-4">
{errormsg!=null &&
<div className='alert alert-danger'>{errormsg}</div>
}
<form autocomplete="off">

<div className="form-group">
<input className="form-control" type="text" placeholder="Name" autofocus />
</div>

<div className="form-group">
<input className="form-control" type="text" placeholder="E-Mail" />
</div>

<div className="form-group">
<select className="form-control"  >
<option value="">-Select Country-</option>
    @foreach($all_country as $list)
    <option value="{{$list->id}}"></option>
    @endforeach
</select>
</div>

<div className="form-group">
<input className="form-control" type="number"  placeholder="Phone" />
</div>
<div className="form-group">
<input className="form-control" type="password"  placeholder="Password" />
</div>
<div className="form-group">
<input className="form-control" type="password" placeholder="Confirm Password" />
</div>

<button className="btn btn-primary w-100" type="button" onClick={submitForm} >
{loader=='off' &&
<span>Sign In</span>
}
{loader=='on' &&
<center><span className="spinner-border spinner-border-sm"></span></center>
}
</button>
</form>
</div>

<div className="login-meta-data text-center">
<div className="divider divider-center-icon border-primary"><i className="fa fa-cog"></i></div>
  <p className="mb-0"><Link className="w-100 btn btn-primary text-white" to="/login">Login Now</Link></p>
</div>
  </div>
</div>

    </motion.div>
  )
}
