import React, { useEffect, useState } from 'react';
import Authuser from '../../api/Authuser';

export default function Coinbuy(props) {
const {http,bUrl} = Authuser();
const token = localStorage.getItem('token');

const [availbal,setAvailbal] = useState([]);
const [qty,setQty] = useState(0);
const [subamount,setSubamount] = useState(0);
const [tradepercent,setTradepercent] = useState([]);
const [tradefee,setTradefee] = useState(0);
const [tamount,setTamount] = useState();
const [coinatprice,setAtprice] = useState([]);

useEffect(()=>{
    getuseravailbalance();
},[]);

const getuseravailbalance = () =>{
http.get('/buysell/availablebal/'+token+'/'+props.pair).then(res=>{
    setAvailbal(res.data);
    });
http.post('/single-coin-pair/',{pair:props.pair,coincode:props.coin}).then(res=>{
    setAtprice(parseFloat(res.data.price));
    });
http.post('/buysell/trade-percentage/'+props.coin).then(res=>{
    setTradepercent(res.data.price);
    });
}

const atpricedecrease = ()=>{
    let datprice = parseFloat(coinatprice-0.0002).toFixed(6);
    let dsubamount = parseFloat(datprice*qty).toFixed(6);
    let dtradefee = parseFloat((tradepercent/100)*dsubamount).toFixed(6);
    let dtotalamount = parseFloat(dsubamount+dtradefee).toFixed(6);
    setAtprice(parseFloat(coinatprice-0.0002).toFixed(6));
    if(qty!='' && qty>=0.0001){
        setSubamount(dsubamount);
        setTradefee(dtradefee);
        setTamount(dtotalamount);
    }
}
const priceincrease = ()=>{
    setAtprice(coinatprice+0.0002);
    let datprice = parseFloat(coinatprice+0.0002).toFixed(6);
    let dsubamount = parseFloat(datprice*qty).toFixed(6);
    let dtradefee = parseFloat((tradepercent/100)*dsubamount).toFixed(6);
    let dtotalamount = parseFloat(dsubamount+dtradefee).toFixed(6);
    setAtprice(parseFloat(datprice).toFixed(6));
        setSubamount(dsubamount);
        setTradefee(dtradefee);
        setTamount(dtotalamount);
}
const quantitydecrease = ()=>{
    if(qty>=0.0002){
    let dqty = parseFloat(qty-0.0002).toFixed(6);
    let dsubamount = parseFloat(coinatprice*qty).toFixed(6);
    let dtradefee = parseFloat((tradepercent/100)*dsubamount).toFixed(6);
    let dtotalamount = parseFloat(dsubamount+dtradefee).toFixed(6);
        setQty(dqty);
        setSubamount(dsubamount);
        setTradefee(dtradefee);
        setTamount(dtotalamount);
    }
}
const quantityincrease = ()=>{
    let dqty = parseFloat(qty+0.0002).toFixed(6);
    let dsubamount = parseFloat(coinatprice*qty).toFixed(6);
    let dtradefee = parseFloat((tradepercent/100)*dsubamount).toFixed(6);
    let dtotalamount = parseFloat(dsubamount+dtradefee).toFixed(6);
        setQty(dqty);
        setSubamount(dsubamount);
        setTradefee(dtradefee);
        setTamount(dtotalamount);
}
const percentage = (percent)=>{
    let bal = (percent/100)*availbal;
    let dsubamount = parseFloat(bal).toFixed(6);
    let dqty = parseFloat(dsubamount/coinatprice).toFixed(6);
    let dtradefee = parseFloat((tradepercent/100)*dsubamount).toFixed(6);
    let dtotalamount = parseFloat(dsubamount+dtradefee).toFixed(6);
    setQty(dqty);
    setSubamount(dsubamount);
    setTradefee(dtradefee);
    setTamount(dtotalamount);
}
const atprice = (e)=>{
    let datprice = e.target.value;
    let dsubamount = parseFloat(datprice*qty).toFixed(6);
    let dtradefee = parseFloat((tradepercent/100)*dsubamount).toFixed(6);
    let dtotalamount = parseFloat(dsubamount+dtradefee).toFixed(6);
    setAtprice(e.target.value);
    if(datprice!='' && qty!='' && datprice>=0){
        setSubamount(dsubamount);
        setTradefee(dtradefee);
        setTamount(dtotalamount);
    }
}
const quantity = (e)=>{
    let dqty = e.target.value;
    setQty(e.target.value);
    if(dqty!='' && dqty>=0.0001){
    let dsubamount = parseFloat(coinatprice*qty).toFixed(6);
    let dtradefee = parseFloat((tradepercent/100)*dsubamount).toFixed(6);
    let dtotalamount = parseFloat(dsubamount+dtradefee).toFixed(6);
    setSubamount(dsubamount);
        setTradefee(dtradefee);
        setTamount(dtotalamount);
    }
}
const totalamount = (e)=>{
    let dtamount = e.target.value;
        if(dtamount==availbal){
            dtamount = parseFloat((availbal*99.75)/100).toFixed(6);
        }
        let dsubfee = dtamount/coinatprice;
        let dqty = parseFloat(dsubfee).toFixed(6);
        let dsubamount = parseFloat(coinatprice*dqty).toFixed(6);
        let dtradefee = parseFloat((tradepercent/100)*dsubamount).toFixed(6); 
        setQty(dqty);
        setSubamount(dsubamount);
        setTradefee(dtradefee);
        setTamount(dtamount);
}

const formsubmit = () =>{
    alert();
}

  return (
    <>

<div className="row mt-2">
    <div className="col-2"><span onClick={atpricedecrease} style={{fontWeight: 'bolder', color:'black', fontSize: '25px', marginLeft: '6px'}}>-</span></div>
    <div className="col-8 text-center"><input onChange={atprice} value={coinatprice} type="number" className="form-control" style={{border:'none', fontSize: '13px', textAlign:'center'}} /></div>
    <div className="col-2"><span onClick={priceincrease} style={{fontWeight: 'bolder', color:'black', fontSize: '24px'}}>+</span></div>
</div><br/>
<div className="row">
    <div className="col-2"><span onClick={quantitydecrease} style={{fontWeight: 'bolder', color:'black', fontSize: '25px', marginLeft: '6px'}}>-</span></div>
    <div className="col-8"><input onChange={quantity} value={qty} type="number" className="form-control" placeholder="Amount" style={{border:'none', fontSize: '13px', textAlign:'center'}} /></div>
    <div className="col-2"><span onClick={quantityincrease} style={{fontWeight: 'bolder', color:'black', fontSize: '24px'}}>+</span></div>
</div><br/>

<div className="row">
    <div className="col-3">
    <h6 style={{color:'black', fontSize: '12px'}} onClick={()=>percentage('25')} >25%</h6>
    </div>
    <div className="col-3">
        <h6 style={{color:'black', fontSize: '12px'}} onClick={()=>percentage('50')} >50%</h6>
    </div>
    <div className="col-3">
        <h6 style={{color:'black', fontSize: '12px'}} onClick={()=>percentage('75')} >75%</h6>
    </div>
    <div className="col-3">
        <h6 style={{color:'black', fontSize: '12px'}} onClick={()=>percentage('99.75')} >100%</h6>
    </div>
</div>

<div className="row" style={{borderTop:'1px solid #949494', borderBottom:'1px solid #949494'}}>
    <div className="col-12">
    <input onChange={totalamount} value={tamount} type="number" className="form-control" placeholder={`Total ${props.pair}`} style={{border:'none', fontSize: '13px', textAlign:'center'}} />
    </div> 
</div>

<div className="row" style={{marginTop:'15px', marginBottom:'10px'}}>
    <div className="col-3" style={{color:'black', fontSize:'12px'}}>Aval: </div>
    <div className="col-9" style={{color:'black', fontSize:'13px', textAlign:'right'}}><b>{availbal}</b> {props.pair}</div>
</div>

<div className="btn btn-success w-100" style={{background: 'green'}} onClick={formsubmit} >
Buy 
{/* <center wire:load>
        <span className="spinner-border spinner-border-sm"></span>
        </center> */}
</div>  
{/* @if (session()->has('msg'))
        <h2 className="alert alert-{{ session('msg_class') }} text-center">
            {{ session('msg') }}
        </h2>
    @endif */}

    </>
  )
}
