import React from 'react';

export default function Coinsell(props) {
  return (
    <>

<div class="row mt-2">
<div class="col-2"><span style={{fontWeight: 'bolder', color:'black', fontSize: '25px', marginLeft: '6px'}}>-</span></div>
<div class="col-8 text-center"><input type="number" class="form-control" style={{border:'none', fontSize: '13px', textAlign:'center'}} /></div>
<div class="col-2"><span style={{fontWeight: 'bolder', color:'black', fontSize: '24px'}}>+</span></div>
</div><br/>
<div class="row">
<div class="col-2"><span style={{fontWeight: 'bolder', color:'black', fontSize: '25px', marginLeft: '6px'}}>-</span></div>
<div class="col-8"><input type="number" class="form-control" placeholder="Amount" style={{border:'none', fontSize: '13px', textAlign:'center'}} /></div>
<div class="col-2"><span style={{fontWeight: 'bolder', color:'black', fontSize: '24px'}}>+</span></div>
</div><br/>

<div class="row">
<div class="col-3">
<h6 style={{color:'black', fontSize: '12px'}} >25%</h6>
</div>
<div class="col-3">
    <h6 style={{color:'black', fontSize: '12px'}} >50%</h6>
</div>
<div class="col-3">
    <h6 style={{color:'black', fontSize: '12px'}} >75%</h6>
</div>
<div class="col-3">
    <h6 style={{color:'black', fontSize: '12px'}} >100%</h6>
</div>
</div>

<div class="row" style={{borderTop:'1px solid #949494', borderBottom:'1px solid #949494'}}>
<div class="col-12">
<input type="number" class="form-control" placeholder="Total" style={{border:'none', fontSize: '13px', textAlign:'center'}} />
</div>
</div>

<div class="row" style={{marginTop:'15px', marginBottom:'10px'}}>
<div class="col-3" style={{color:'black', fontSize:'12px'}}>Aval: </div>
<div class="col-9" style={{color:'black', fontSize:'13px', textAlign:'right'}}><b></b> </div>
</div>

<div class="btn btn-danger w-100" style={{background:'red'}} >
Sell {props.coincode}
</div>

    </>
  )
}
