import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { animationone } from '../../animations/Animation';
import Authuser from '../../api/Authuser';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Coinbuy from './Coinbuy';
import Coinsell from './Coinsell';
import Liveprice from './Liveprice';

export default function Buysell() {
const {coincode} = useParams();
const {pairname} = useParams();
const {tradetype} = useParams();
const {http,bUrl} = Authuser();
const token = localStorage.getItem('token');
const navigate = useNavigate();
const [countrydetails,setCountrydetails] = useState([]);
const [singlecoinpair,setSingleCoinpair] = useState([]);
const [coinpairprice,setCoinpairprice] = useState([]);
const [forhistory,setForhistory] = useState([]);
const [page,setPage] = useState(10);
const [openorders,setOpenorders] = useState([]);
const [forbuy,setForbuy] = useState([]);
const [forsell,setForsell] = useState([]);
const [allpairs,setAllpairs] = useState([]);

useEffect(()=>{
getusercountrydetails();
getbuyselldetails();
},[]);

const getusercountrydetails = () =>{
  http.get('/user-country-details/'+token).then(res=>{
    setCountrydetails(res.data);
  });
  http.post('/single-coin-pair/',{pair:pairname,coincode:coincode}).then(res=>{
    setSingleCoinpair(res.data);
  });
  http.get('/coin-pair-price/'+pairname).then(res=>{
    setCoinpairprice(res.data);
  });
}

const getbuyselldetails = () =>{
http.get('/buysell/forhistory/'+token).then(res=>{
    setForhistory(res.data.data);
});
http.get('/order-trade-details/'+coincode+'/'+pairname).then(res=>{
  setForbuy(res.data.forbuy.data);
  setForsell(res.data.forsell.data);
});
http.get('/buysell/all-pairs/'+token).then(res=>{
    setAllpairs(res.data);
    console.log(res.data);
});
http.get('/orders-detail/'+token+'/'+page).then(res=>{
  setOpenorders(res.data.openorder);
});
}
    

  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >  
    <Helmet>
        <title>Maxpayz | BuySell</title>
        <link rel="stylesheet" href="/assets/css/buysellpage.css" />
    </Helmet>

<div className="header-area" id="headerArea" style={{background:'white'}}>
<div className="container">
<div className="header-content position-relative d-flex align-items-center justify-content-between">

<div className="back-button"><Link reloadDocument to={`/graph/${coincode}/${pairname}`} className=""> 
<svg className="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
</svg></Link></div>
<div className="page-heading">
<h6 className="mb-0" style={{fontWeight:'bolder', fontSize:'18px'}}>
{coincode}/{pairname}
</h6>
</div>
<div className="setting-wrapper"><a className="setting-trigger-btn" href="#"> 
<img src="/assets/images/logo.png" alt="logo" style={{height: '28px'}} />
</a>
</div>
</div>
</div>
</div>  


<div className="page-content-wrapper" style={{background:'white', borderTop:'1px solid black'}}>
<div className="w-100" style={{background:'white'}}>
<div className="row" style={{borderBottom: '1px solid #949494'}}>

<div className="col-7" style={{borderRight: '1px solid #949494'}}>
<div className="card">
<div className="card-body">
<div className="accordion accordion-style-three" id="accordionStyle3">
<div className="row">

<div className="col-6">
<div className="accordion-item">
<div className="accordion-header" id="accordionSeven">
    <h6 className={`shadow-sm rounded border ${tradetype==='Buy'?'':'collapsed'}`} style={{background: 'green',color: 'white',fontWeight: 'bolder'}} data-bs-toggle="collapse" data-bs-target="#accordionStyleSeven" aria-expanded="true" aria-controls="accordionStyleSeven">
        BUY
    <svg className="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
    </svg>
    </h6>
</div>
</div>
</div>

<div className="col-6" style={{marginLeft: '-10px'}}>
<div className="accordion-item">
<div className="accordion-header" id="accordionEight">
    <h6 className={`shadow-sm rounded border ${tradetype==='Sell'?'':'collapsed'}`} style={{background: 'red',color: 'white',fontWeight: 'bolder', marginLeft: '12px'}} data-bs-toggle="collapse" data-bs-target="#accordionStyleEight" aria-expanded="false" aria-controls="accordionStyleEight">
        SELL
    <svg className="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
    </svg>
    </h6>
</div>
</div>
</div>


{/* <!--Buy Body---------------> */}
<div className={`accordion-collapse collapse ${tradetype=='Buy'?'show':''}`} id="accordionStyleSeven" aria-labelledby="accordionSeven" data-bs-parent="#accordionStyle3">
    
<Coinbuy coin={coincode} pair={pairname} />
    
</div>


{/* <!--Sell Body-------------> */}
<div className={`accordion-collapse collapse ${tradetype=='Sell'?'show':''}`} id="accordionStyleEight" aria-labelledby="accordionEight" data-bs-parent="#accordionStyle3">
    
<Coinsell coin={coincode} pair={pairname} />
    
</div>


</div>
</div>
</div>
</div>
</div>

{/* Sidebar Live Price */}
<div className="col-5">
<table className="table mb-0" style={{marginLeft: '-2px'}}>
<thead style={{color:'black', borderBottom:'1px solid #949494'}}> 
  <tr>
    <th scope="col" style={{textAlign:'left', fontSize: '11px'}}>Price {pairname}</th>
    <th scope="col" style={{textAlign:'right', fontSize: '11px'}}>Amount {coincode}</th>
  </tr>
</thead>
<tbody>
{forbuy.map((item,index)=>( 
  <tr>
    <td style={{color:'green', textAlign:'left', fontSize: '13px'}}>{parseFloat(item.at_price)}</td>
    <td style={{color:'green', textAlign:'left', fontSize: '13px'}}>{parseFloat(item.left_qty)}</td>
  </tr>
))}

  <tr style={{borderBottom:'1px solid #949494', borderTop:'1px solid #949494'}}>
    <td colSpan="2" style={{textAlign:'center'}}>
    <span id="coin_prices" style={{fontSize:'15px', fontWeight:'bolder'}}>{parseFloat(singlecoinpair.price)}</span><br/>
    <span><img src={`${bUrl}storage/${countrydetails.colorsymbol}`} style={{width:'15px', height:'10px', marginRight: '-2px'}} /></span>
    {singlecoinpair.pairtype==='Country' ? 
    <span id="nb_price" style={{fontSize:'11px'}}> {(singlecoinpair.price*countrydetails.plusvalue).toFixed(2)}</span>
    :
    pairname==='USDT' ?
    <span id="nb_price" style={{fontSize:'11px'}}> {(singlecoinpair.price*countrydetails.normalvalue).toFixed(2)}</span>
    :
    <span id="nb_price" style={{fontSize:'11px'}}> {(singlecoinpair.price*countrydetails.normalvalue*coinpairprice).toFixed(2)}</span>
    }
  </td>
  </tr>

  {forsell.map((item,index)=>(
  <tr>
    <td style={{color:'red', textAlign:'left', fontSize: '13px'}}>{parseFloat(item.at_price)}</td>
    <td style={{color:'red', textAlign:'left', fontSize: '13px'}}>{parseFloat(item.left_qty)}</td>
  </tr>
  ))}
  
</tbody>
</table>
</div>
</div>

<Liveprice coin={coincode} pair={pairname} />

<div className="row">
<div className="card">
<div className="card-body">
<div className="accordion accordion-style-three" id="accordionStyle5">
<div className="row" style={{borderBottom: '1px solid #949494', height:'40px', marginBottom:'7px'}}>

<div className="col-4">
<div className="accordion-item">
  <div className="accordion-header" id="accordionorder">
    <h6 className="rounded text-center" style={{color:'black', fontWeight:'bolder', fontSize: '16px'}} data-bs-toggle="collapse" data-bs-target="#accordionStyleorder" aria-expanded="true" aria-controls="accordionStyleorder">
        Order
    </h6>
  </div>
</div>
</div>

<div className="col-4" style={{marginLeft: '-10px'}}>
<div className="accordion-item">
  <div className="accordion-header" id="accordiontrade">
    <h6 className="rounded collapsed text-center" style={{color:'black', fontWeight:'bolder', marginLeft:'30px', fontSize: '16px'}} data-bs-toggle="collapse" data-bs-target="#accordionStyletrade" aria-expanded="false" aria-controls="accordionStyletrade">
        Trade
    </h6>
  </div>
</div>
</div>

<div className="col-4" style={{marginLeft: '-10px'}}>
<div className="accordion-item">
  <div className="accordion-header" id="accordionfund">
    <h6 className="rounded collapsed text-center" style={{color:'black', fontWeight:'bolder', marginLeft:'65px', fontSize: '16px'}} data-bs-toggle="collapse" data-bs-target="#accordionStylefund" aria-expanded="false" aria-controls="accordionStylefund">
        Fund
    </h6>
  </div>
</div>
</div>

</div>

{/* <!--order Body---------------> */}
<div className="accordion-collapse collapse show" id="accordionStyleorder" aria-labelledby="accordionorder" data-bs-parent="#accordionStyle5">
<table className="table mb-0 table-striped">
<tbody>
{openorders.map((item,index)=>(
<tr style={{borderBottom:'0.5px solid white !important'}} key={item.id}>
<td>
{item.type==='Buy' ?
<span className="text-success">BUY</span> :
<span className="text-danger">SELL</span>
}
<br/>
<span style={{color:'#0D86BB', lineHeight: '65px'}}>{parseFloat(item.left_percent)}%</span>
</td>

<td>
<span style={{color:'#BCBDBD', textAlign:'center'}}>{item.coin_code} / {item.pair_name}</span><br/>
<span style={{color:'#BCBDBD', float:'left', fontSize:'12px'}}>Qnty:</span>
<span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}> 
{parseFloat((item.qty*item.left_percent)/100)}/{parseFloat(item.qty)}</span><br/>
<span style={{color:'#BCBDBD', float:'left', fontSize:'12px'}}>Price:</span><span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}>  {parseFloat(item.at_price)}</span><br/>
<span style={{color:'#BCBDBD', float:'left', fontSize:'12px'}}>Total:</span><span style={{color:'#BCBDBD', float:'right', fontSize:'12px'}}>  {parseFloat(item.total)}</span>
</td>

<td>
<span style={{color:'#BCBDBD'}}>{item.date}</span><br/>
<span style={{color:'#BCBDBD'}}>{item.time}</span><br/>
<span className="badge bg-info">Cancel</span>
</td>

</tr>
))}
</tbody>
</table> 
</div>

{/* <!--trade Body-------------> */}
<div className="accordion-collapse collapse" id="accordionStyletrade" aria-labelledby="accordiontrade" data-bs-parent="#accordionStyle5">
<table className="table mb-0 table-striped">
<tbody>
{forhistory.map((item,index)=>( 
<tr style={{borderBottom:'0.5px solid white !important'}}>
<td>
{item.type==='Buy' ?
<span className="text-success">BUY</span> :
<span className="text-danger">SELL</span>
}
<br/>
{100-(item.left_percent)==0 ?
<span style={{color:'#0D86BB', lineHeight: '65px'}}>100%</span> :
<span style={{color:'#0D86BB', lineHeight: '65px'}}>{100-(item.left_percent)}%</span>
}
</td>

<td>
<span style={{color:'black', textAlign:'center'}}>{item.coin_code} / {item.pair_name}</span><br/>
<span style={{color:'black', float:'left', fontSize:'12px'}}>Qnty:</span>
{(item.qty-item.left_qty)===0 ?
<span style={{color:'black', float:'right', fontSize:'12px'}}> 
{item.qty}
</span> :
<span style={{color:'black', float:'right', fontSize:'12px'}}> 
{item.qty-item.left_qty}
</span> 
}
<br/>
<span style={{color:'black', float:'left', fontSize:'12px'}}>Price:</span><span style={{color:'black', float:'right', fontSize:'12px'}}>  {parseFloat(item.at_price)}</span><br/>
<span style={{color:'black', float:'left', fontSize:'12px'}}>Total:</span>
{item.status===3 ? 
<span style={{color:'black', float:'right', fontSize:'12px'}}>  
{parseFloat(item.total-item.left_total)}
</span> :
<span style={{color:'black', float:'right', fontSize:'12px'}}>  
{parseFloat(item.total)}
</span>
}
</td>
<td style={{textAlign:'right'}}>
<span style={{color:'black',fontSize:'11px'}}>{item.date} {item.time}</span><br/>
{item.status===0 ?
<span className="badge bg-danger">Cancelled</span> :
item.status===2 && item.type==='Buy' ?
<span className="badge bg-success">Completed</span> :
item.status===2 && item.type==='Sell' ?
<span className="badge bg-success">Completed</span> :
<span className="badge bg-info">Partially<br/>Completed</span> 
}
</td>
</tr>
))}
</tbody>
</table>
</div>

{/* <!--Fund Body-------------> */}
<div className="accordion-collapse collapse" id="accordionStylefund" aria-labelledby="accordionfund" data-bs-parent="#accordionStyle5">
<table className="table mb-0 table-striped">
<tbody>
  {/* Country Fund */}
{/* <tr style={{borderBottom:'0.5px solid white !important'}}>
    <td></td>
    <td style={{textAlign:'left'}}>{allpairs.allcountrypairs.pair_name}<br/>
    </td>
    <td style={{textAlign:'right'}}>{allpairs.totalfund}</td>
  </tr> */}

  {/* Coin Fund */}
{/* {(allpairs.allpairs).map((item,index)=>( 
<tr style={{borderBottom:'0.5px solid white !important'}}>  
<td style={{textAlign:'center'}}><img src={`${bUrl}storage/${item.coin.symbol}`} style={{width:'25px', height:'25px'}} /></td>
<td style={{textAlign:'left'}}>{item.coin_code}<br/><span style={{fontSize: '11px'}}>{item.coin.coin_name}</span></td>
<td style={{textAlign:'right'}}>{parseFloat((item.amount+item.total_buy+item.total_incentive)-item.withdraw_amount-item.total_sell)}<br/>
<img src={`${bUrl}storage/${countrydetails.colorsymbol}`} style={{height:'10px', marginTop: '-1px'}} />
<span style={{fontSize: '11px'}}>

</span>
</td>
</tr>
))} */}
</tbody>
</table>
</div>

</div>
</div>
</div>
</div>


</div>
</div>

    </motion.div>
  )
}
