import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export default function Authuser() { 

const navigate = useNavigate();

const bUrl = "https://adminworld.maxpayz.tech/";
const murl = "https://maxpayz.tech/";

const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    const encodetoken = localStorage.getItem('encodeuserid'); 
    return encodetoken;
}

const logout = () => {
    localStorage.clear();
    navigate('/');
}

const [token,setToken] = useState();
const [flag,setFlag] = useState();
const [encodeuserid,setEncodeuserid] = useState();

const saveToken = (token,flag,encodeuserid) => {
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('encodeuserid', encodeuserid);
    localStorage.setItem('flag', flag);
    setToken(token);
    setFlag(flag);
    setEncodeuserid(encodeuserid);
    navigate('/dashboard');
}
  
    const http = axios.create({
        baseURL: "https://adminworld.maxpayz.tech/api",
        headers: {
            "content-type":"application/json"
        }
    });

    return {
        setToken:saveToken,
        token,
        flag,
        encodeuserid,
        getToken,
        http,
        bUrl,
        murl,
        logout
    }

}
