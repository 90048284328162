import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Authuser from '../../api/Authuser';

export default function Favouritesprice(props) {
  let canceltoken;
const {http} = Authuser();
const [favcoinprice,setFavcoinprice] = useState([]);
useEffect(()=>{
    getfavcoinprice();
},[]);  

const getfavcoinprice = () =>{
  canceltoken = axios.CancelToken.source(); 
  http.get('/hot-vol-coin-price/'+props.pair+'/'+props.coin,{canceltoken:canceltoken.token}).then(res=>{
      setFavcoinprice(res.data);
  });
  }

  return (
    <>

<td style={{textAlign:'right'}}>{parseFloat(favcoinprice.coinprice)}</td>
<td style={{textAlign:'right'}}>
{favcoinprice.percentage>=0 &&
    <span className="badge" style={{background:'green'}} id={`percent${props.unid}`} >{favcoinprice.percentage}</span>  
}
{favcoinprice.percentage<0 &&
    <span className="badge" style={{background:'red'}} id={`percent${props.unid}`} >{favcoinprice.percentage}</span>  
}
</td>

    </>
  )
}
