

    export const animationone = {
        in: {
            opacity: 5,
            x: -600
        },
        out: {
            opacity: 0,
            x: 600
        },
        end: {
            x: 0,
            opacity: 5
        }
    }
    
    export const animationtwo = {
        in: {
            opacity: 1,
            y: 0,
            scale: 1
        },
        out: {
            opacity: 0,
            y: '-100vh',
            scale: 0.3
        }
    }
    
    
  

