import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Authuser from '../../api/Authuser';
// import Livehotvol from './Livehotvol';

export default function Gainerprice(props) {
 let canceltoken;
const {http} = Authuser();
const [gainercoins,setGainercoins] = useState([]);
useEffect(()=>{
    getgainercoinprice();
},[]);

const getgainercoinprice = () =>{
canceltoken = axios.CancelToken.source();   
http.get('/gainer-coin-price/'+props.pair+'/'+props.coin,{canceltoken:canceltoken.token}).then(res=>{
    setGainercoins(res.data);
});    
}

  return (
    <>

<td style={{textAlign:'right'}} id={`coin_prices${props.unid}`}>{parseFloat(gainercoins.coinprice)}</td>
<td style={{textAlign:'right'}}>
{gainercoins.percentage>=0 &&
    <span className="badge" style={{background:'green'}} id={`percent${props.unid}`} >{gainercoins.percentage}</span>  
}
{gainercoins.percentage<0 &&
    <span className="badge" style={{background:'red'}} id={`percent${props.unid}`} >{gainercoins.percentage}</span>  
}
</td>
{/* <Livehotvol coin={props.coin} pair={props.pair} uniid={props.unid} /> */}

    </>
  )
}
