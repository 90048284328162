import React, { useEffect, useState } from 'react';
import Authuser from '../../api/Authuser'; 

export default function Favcoincheck(props) {

    const {http,bUrl} = Authuser();
    const token = localStorage.getItem('token');
    let coincode = props.coin;
    let pair = props.pair;
    const [favcoins,setFavcoins] = useState([]);
    useEffect(()=>{
        getallfavcoins();
    },[]);
    const getallfavcoins = () =>{
        http.post('/all-fav-coins/',{pair:pair,coincode:coincode,userid:token}).then(res=>{
            setFavcoins(res.data);
        });
    };

    const addingfav = (coincode,pair) =>{
        http.post('/add-favourite-coin/',{pair:pair,coincode:coincode,userid:token}).then(res=>{
            getallfavcoins();
        });
    }

  return (
    <>
        <td className="col-6" style={{textAlign:'center'}} key={coincode}>
         {favcoins===1 ?   
        <input type="checkbox" checked onClick={() => addingfav(coincode,pair)} />
        :
        <input type="checkbox" onClick={() => addingfav(coincode,pair)} />
         }
        </td>
    </>
  )
}
