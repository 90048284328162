import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Authuser from '../../api/Authuser';
import Hotvolcoinprice from './Hotvolcoinprice';
import { useNavigate } from 'react-router-dom';

export default function Hotvol() {
let canceltoken;
const {http,murl,bUrl} = Authuser();
const navigate = useNavigate();
const token = localStorage.getItem('token');
const [countrypair,setCountryPairs] = useState([]);
const [coinpair,setCoinPairs] = useState([]);
const [defaultpair,setDefaultpair] = useState('USDT');
const [hotvolcoins,setHotvolcoins] = useState([]);
const [loader,setLoader] = useState('off');
useEffect(()=>{
    getcountrypairs();
    getcoinpairs();
    gethotvolcoins();
},[]);

const getcountrypairs = () =>{
canceltoken = axios.CancelToken.source();  
http.get('/user-country-pair/'+token,{canceltoken:canceltoken.token}).then(res=>{
    setCountryPairs(res.data);
});
};

const getcoinpairs = () =>{
canceltoken = axios.CancelToken.source(); 
http.get('/coin-pairs',{canceltoken:canceltoken.token}).then(res=>{
    setCoinPairs(res.data);
});
}

const gethotvolcoins = () =>{
  setLoader('on');
canceltoken = axios.CancelToken.source();   
http.get('/hot-vol-coins/'+defaultpair,{canceltoken:canceltoken.token}).then(res=>{
  setHotvolcoins(res.data.hotvol);
  setLoader('off');
});
}

const pairchange = (e)=>{
canceltoken = axios.CancelToken.source();    
  setDefaultpair(e.target.value);
  setHotvolcoins([]);
  http.get('/hot-vol-coins/'+e.target.value,{canceltoken:canceltoken.token}).then(res=>{
    setHotvolcoins(res.data.hotvol);
  });
}

const graphpage = (id,pair) =>{
  navigate('/graph/'+id+'/'+pair);
  navigate(0);
}

return (
<>

<table className="table mb-0 table-striped" style={{marginTop: '-13px'}}>
<thead style={{fontSize:'13px', color:'#949494'}}>
<tr>
  <th scope="col" >
  <select style={{color:'#949494', border:'none'}} onChange={pairchange} >
    <option value={countrypair.pair_name} selected={countrypair.pair_name==defaultpair && 'selected'} >{countrypair.pair_name} Markets</option> 
    {coinpair.map((item,index)=>(
    <option value={item.pair_name} selected={item.pair_name==defaultpair && 'selected'} >{item.pair_name} Markets</option>    
    ))}
  </select>
  </th>
  <th scope="col" style={{textAlign:'right'}}>Last Price</th> 
  <th scope="col" style={{textAlign:'right'}}>24h Chg%</th> 
</tr>
</thead>

{loader==='on' &&
<tbody>
<tr>
<td colSpan={3}><center style={{marginTop:'20px'}}><span style={{color:'black'}}>Loading...</span></center></td>
</tr>
</tbody>
}
{loader==='off' &&
<tbody>
{hotvolcoins.map((item,index)=>(
  <tr style={{fontSize:'13px', color:'#000035', fontWeight:'bolder'}} key={item.id} onClick={() => graphpage(item.coin,defaultpair)} >
    <td><img src={`${bUrl}storage/${item.icon}`} style={{width:'20px', height:'20px'}} /> &nbsp; {item.coin} / {defaultpair}</td>
    <Hotvolcoinprice coin={item.coin} pair={defaultpair} unid={item.id} /> 
  </tr>
))}
</tbody>
}


</table>

    </>
  )
}
