import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Authuser from '../../api/Authuser';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation'; 
import {Helmet} from "react-helmet"; 
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Hotvol from './Hotvol';
import Gainers from './Gainers';
import Losers from './Losers';
import Favouritesprice from './Favouritesprice';


export default function Dashboard() {
let canceltoken;
const images = [
  '/assets/images/sli7.png',
  '/assets/images/sli8.png',
  '/assets/images/sli1.png',
  '/assets/images/sli2.png',
  '/assets/images/sli3.png',
  '/assets/images/sli4.png',
  '/assets/images/sli5.png',
];

const {http} = Authuser();
const token = localStorage.getItem('token');
const navigate = useNavigate();
const [favourite,setFavourite] = useState([]);
useEffect(()=>{
  getfavcoins();
},[]);

const getfavcoins = ()=>{
canceltoken = axios.CancelToken.source();
http.get('/favourite-coin/'+token,{canceltoken:canceltoken.token}).then(res=>{ 
  setFavourite(res.data);
});
}

const graphpage = (id,pair) =>{
  navigate('/graph/'+id+'/'+pair);
  navigate(0);
}
    
return (
<motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >    

<Helmet>
    <title>Maxpayz | Dashboard</title>
    <link rel="stylesheet" href="/assets/css/dashboard.css" />
</Helmet>

<div id="myModal" className="modal" tabindex="-1">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Modal title</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <p>Modal body text goes here.</p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>

<div className="page-content-wrapper">
<div className="owl-carousel-one owl-carousel">
<Zoom scale={0.4}>
          {
            images.map((each, index) => <img key={index} style={{width: "100%"}} src={each} />)
          }
        </Zoom>
</div>

<div className="pt-1"></div>

<div className="container">
<div className="card image-gallery-card">
<div className="card-body">
<div className="row g-3">

<div className="col-3">
<Link to="/market">
<div className="single-image-gallery"><img src="/assets/images/1.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>Market</center>
</div>
</Link>
</div>

<div className="col-3">
<a href="#">
<div className="single-image-gallery"><img src="/assets/images/4.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>Staking</center>
</div>
</a>
</div>

<div className="col-3">
<a href="#">
<div className="single-image-gallery"><img src="/assets/images/3.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>Earn</center>
</div>
</a>
</div>

<div className="col-3">
<Link to="/wallet">
<div className="single-image-gallery"><img src="/assets/images/2.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>Wallet</center>
</div>
</Link>
</div>

<div className="col-3" style={{marginTop: '5px'}}>
<a href="#">
<div className="single-image-gallery"><img src="/assets/images/5.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>Future</center>
</div>
</a>
</div>

<div className="col-3" style={{marginTop: '5px'}}>
<a href="#">
<div className="single-image-gallery"><img src="/assets/images/6.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>Swap</center>
</div>
</a>
</div>

<div className="col-3" style={{marginTop: '5px'}}>
<a href="#">
<div className="single-image-gallery"><img src="/assets/images/7.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>Loan</center>
</div>
</a>
</div>

<div className="col-3" style={{marginTop: '5px'}}>
<a href="#">
<div className="single-image-gallery"><img src="/assets/images/8.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>Convert</center>
</div>
</a>
</div>

<div className="col-3" style={{marginTop: '5px'}}>
<a href="#">
<div className="single-image-gallery"><img src="/assets/images/api.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>API</center>
</div>
</a>
</div>

<div className="col-3" style={{marginTop: '5px'}}>
<a href="#">
<div className="single-image-gallery"><img src="/assets/images/10.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>MTA</center>
</div>
</a>
</div>

<div className="col-3" style={{marginTop: '5px'}}>
<a href="#">
<div className="single-image-gallery"><img src="/assets/images/11.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>P2P</center>
</div>
</a>
</div>

<div className="col-3" style={{marginTop: '5px'}}>
<Link to="/add-favourites">
<div className="single-image-gallery"><img src="/assets/images/12.png" alt=""/>
    <center style={{color:'#000035',fontSize: '14px',fontWeight: '600'}}>CYF</center>
</div>
</Link>
</div>

</div>
</div>
</div>
</div>



<div className="w-100">
<div className="card">
<div className="card-body" style={{marginTop: '-15px'}}>
<div className="standard-tab">
<ul className="nav rounded-lg mb-1 p-1 shadow-sm" id="affanTabs1" role="tablist">

<li className="nav-item" role="presentation">
    <button className="btn" id="favourites-tab" data-bs-toggle="tab" data-bs-target="#favourites" type="button" role="tab" aria-controls="bootstrap" aria-selected="true">Favourites</button>
</li>
<li className="nav-item" role="presentation">
    <button className="btn" id="gainers-tab" data-bs-toggle="tab" data-bs-target="#Gainers" type="button" role="tab" aria-controls="pwa" aria-selected="false">Gainers</button>
</li>
<li className="nav-item" role="presentation">
    <button className="btn" id="Losers-tab" data-bs-toggle="tab" data-bs-target="#Losers" type="button" role="tab" aria-controls="dark" aria-selected="false">Losers</button>
</li>
<li className="nav-item" role="presentation">
    <button className="btn active" id="Volume-tab" data-bs-toggle="tab" data-bs-target="#Volume" type="button" role="tab" aria-controls="dark" aria-selected="false">Hot&Vol</button>
</li>

</ul>
<div className="tab-content rounded-lg p-1 shadow-sm" id="affanTabs1Content">

{/* <!--Favourites--> */}
<div className="tab-pane fade fade" id="favourites" role="tabpanel" aria-labelledby="favourites-tab">

<table className="table mb-0 table-striped" style={{marginTop: '-13px'}}>
  <thead style={{fontSize:'13px', color:'#949494'}}>
    <tr>
      <th scope="col" >Spot</th>
      <th scope="col" style={{textAlign:'right'}}>Last Price</th>
      <th scope="col" style={{textAlign:'right'}}>24h Chg%</th>
    </tr>
  </thead>
<tbody>

{favourite.map((item,index)=>(
  <tr style={{fontSize:'13px', color:'#000035', fontWeight:'bolder'}} key={item.id} onClick={() => graphpage(item.coin_code,item.pair_name)}>
    <td>{item.coin_code} / {item.pair_name}</td>
    <Favouritesprice coin={item.coin_code} pair={item.pair_name} unid={item.id} />
  </tr>
))}

</tbody>
</table>

</div>


{/* <!--Gainers--> */}
<div className="tab-pane fade" id="Gainers" role="tabpanel" aria-labelledby="gainers-tab">
<Gainers />    
</div>

{/* <!--Losers--> */}
<div className="tab-pane fade" id="Losers" role="tabpanel" aria-labelledby="Losers-tab">
<Losers />    
</div>

{/* <!--Volumne--> */}
<div className="tab-pane show active" id="Volume" role="tabpanel" aria-labelledby="Volume-tab">
<Hotvol />
</div>

</div>
</div>
</div>
</div>
</div>



</div>


<Helmet>
    <script src="/assets/js/jquery.min.js"></script>
    <script src="/assets/js/bootstrap.bundle.min.js"></script>
    <script src="/assets/js/internet-status.js"></script>
    <script src="/assets/js/waypoints.min.js"></script>
    <script src="/assets/js/jquery.easing.min.js"></script>
    <script src="/assets/js/wow.min.js"></script>
    <script src="/assets/js/jquery.counterup.min.js"></script>
    <script src="/assets/js/jquery.countdown.min.js"></script>
    <script src="/assets/js/imagesloaded.pkgd.min.js"></script>
    <script src="/assets/js/isotope.pkgd.min.js"></script>
    <script src="/assets/js/jquery.magnific-popup.min.js"></script>
    <script src="/assets/js/dark-mode-switch.js"></script>
    <script src="/assets/js/ion.rangeSlider.min.js"></script>
    <script src="/assets/js/jquery.dataTables.min.js"></script>
    <script src="/assets/js/active.js"></script>
</Helmet>

    </motion.div>
  )
}
