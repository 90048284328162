import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animationone } from '../../animations/Animation'; 
import {Helmet} from "react-helmet";
import Authuser from '../../api/Authuser'; 

export default function Market() {
    let canceltoken;
const {http,bUrl} = Authuser();
const navigate = useNavigate();
const token = localStorage.getItem('token');
const [defaultcoins,setDefaultCoins] = useState([]);
const [pairtcoins,setPairCoins] = useState([]);
const [countrypair,setCountryPairs] = useState([]);
const [coinpair,setCoinPairs] = useState([]);
const [countrydetails,setCountrydetails] = useState([]); 
const [loader,setLoader] = useState('off');
const [hasMore,setHasMore] = useState(true);
const [ddata,setDdata] = useState(300);
const [query,searchQuery] = useState("");

useEffect(()=>{
    getdefaultcoins();
    getcountrypairs();
    getcoinpairs();
    getusercountrydetails();
},[]);

const getdefaultcoins = () =>{
    canceltoken = axios.CancelToken.source();
    http.get('/coin-default-usdt/'+'USDT',{canceltoken:canceltoken.token}).then(res=>{
        setDefaultCoins(res.data);
    });
    setLoader('on');
    http.get('/coin-usdt/'+'USDT'+'/'+ddata,{canceltoken:canceltoken.token}).then(res=>{
        setPairCoins(res.data.data);
        setLoader('off');
    });
  };

const getcountrypairs = () =>{
    canceltoken = axios.CancelToken.source();
http.get('/user-country-pair/'+token,{canceltoken:canceltoken.token}).then(res=>{
    setCountryPairs(res.data);
});
};

const getcoinpairs = () =>{
    canceltoken = axios.CancelToken.source();
    http.get('/coin-pairs',{canceltoken:canceltoken.token}).then(res=>{
        setCoinPairs(res.data);
    });
}

const pairchange = (pair_name) =>{
    navigate('/pchangetwo/market/'+pair_name);
}

const getusercountrydetails = () =>{
    canceltoken = axios.CancelToken.source();
    http.get('/user-country-details/'+token,{canceltoken:canceltoken.token}).then(res=>{
        setCountrydetails(res.data);
    });
}

const graphpage = (id,pair) =>{
    navigate('/graph/'+id+'/'+pair);
    navigate(0);
}

const fetchmoreData = ()=>{
    let mdata = ddata + 50;
    setDdata(ddata+50);
        http.get('/coin-usdt/'+'USDT'+'/'+mdata).then(res=>{
            setPairCoins(res.data.data);
        });
}


  return (
    <motion.div transition={{ type:'tween', duration:0.2 }} initial="out" animate="end" exit="out" variants={animationone} >   

<Helmet>
    <title>Maxpayz | Market</title>
    <link rel="stylesheet" href="/assets/css/market.css" />
</Helmet>    

<div className="page-content-wrapper"> 

<div className="breadcrumb-wrapper breadcrumb-colorful" style={{background:'#E2E9FE', position:'fixed', width:'100%'}}>
<div className="container">
    <nav aria-label="breadcrumb">
    <marquee direction="left" style={{background:'#E2E9FE', marginTop:'5px'}}>
    {defaultcoins.map((dc,index)=>(
    <span key={dc.id}>
    <span><img src={`${bUrl}storage/${dc.symbol}`} style={{width:'25px', height:'25px'}} /></span> &nbsp;
    <span style={{color:'#230FA1'}}>{dc.coin_code} / {dc.pair_with}</span> &nbsp;
    {dc.change_percentage>=0 &&
    <span style={{color:'green'}}>{dc.change_percentage}%</span>
    }
    {dc.change_percentage<0 &&
    <span style={{color:'red'}}>{dc.change_percentage}%</span>
    }
    &nbsp;&nbsp;&nbsp;&nbsp;
    </span>
    ))}
    </marquee>
    </nav>
</div>
</div>

<div className="w-100">
<div className="card" style={{background:'#05002E'}}>

<div className="card-body">
<div className="colorful-tab">
<ul className="nav mb-2 " id="affanTab3" role="tablist" style={{position:'fixed', marginTop: '28px', background:'#05002E', width:'95%'}}>

<div className="input-group w-100">
    <button className="btn btn-dark" type="button"><i className="fa fa-search"></i></button>
    <input className="form-control form-control-clicked" onChange={(e)=>searchQuery(e.target.value)} id="txt_searchall" type="search" placeholder="Making Closer Digitally" style={{marginLeft: '-30px', height:'45px', background: '#05002D', border:'none', color:'white', borderBottom: '1px solid #0F243E', textAlign:'center'}} />
</div>

{/* <!-- Country Pair List --> */}
<center style={{marginTop:'0px'}}>
<li className="nav-item" role="presentation" style={{border:'none'}}>
<span className="btn-pair" onClick={() => pairchange(countrypair.pair_name)} >
    {countrypair.pair_name}
</span>
</li>
</center>

{/* <!-- Coin Pair List --> */}
{coinpair.map((item,index)=>(
<center style={{marginTop:'0px'}} key={item.id}>
<li className="nav-item" role="presentation" style={{border:'none'}}>
<span className={`btn-pair ${item.pair_name == 'USDT' && 'active'}`} onClick={() => pairchange(item.pair_name)} >
    {item.pair_name}
</span>
</li>
</center>
))}

</ul>


<div className="tab-content shadow-sm " id="affanTab3Content">
<div className="tab-pane fade show active" id="USDT" role="tabpanel" aria-labelledby="USDT-tab">
{/* <InfiniteScroll
    dataLength={pairtcoins.length}
    next={fetchmoreData}
    hasMore={hasMore}  
    > */}
<table className="table mb-0 text-white coin_table">
    <thead style={{background:'#05002E', position:'fixed', marginTop: '96px', width:'95%', borderTop: '1px solid #0F243E'}}>
    <tr>
        <th scope="col" className="col-1"></th>
        <th scope="col" className="col-5" style={{textAlign:'left', color:'rgba(255, 255, 255, 0.5)'}}>Crypto</th>
        <th scope="col" className="col-2" style={{textAlign:'left', color:'rgba(255, 255, 255, 0.5)'}}>Price</th>
        <th scope="col" className="col-1" style={{textAlign:'left', color:'rgba(255, 255, 255, 0.5)'}}>Change</th>
    </tr>
    </thead><br/><br/><br/><br/><br/><br/><br/>
    {loader==='on' &&
    <tbody>
    <tr>
    <td colSpan={4} style={{background:'black'}}><center style={{marginTop:'20px'}}><span><img src="/assets/images/loading.gif" /></span></center></td>
    </tr>
    </tbody>
    }
    {loader==='off' &&
    <tbody>
    {pairtcoins.filter((item)=>item.coin_code.toLowerCase().includes(query.toLowerCase())).map((item,index)=>(  
    <tr style={{background:'#000066'}} key={item.id} onClick={() => graphpage(item.coin_code,item.pair_with)} >
    <td className="col-3">
    <img src={`${bUrl}storage/${item.symbol}`} style={{width:'25px', height:'25px'}} />
    </td>
    <td className="col-5" style={{textAlign:'left'}}>
        {item.coin_code} / <span style={{color:'rgba(255, 255, 255, 0.5)', fontSize:'10px'}}>{item.pair_with}</span><br/>
        <span style={{color:'rgba(255, 255, 255, 0.5)', fontSize:'10px'}}>{item.coin_name}</span>
    </td>
    <td className="col-6" style={{textAlign:'right'}}>
        <span style={{fontSize:'12px'}} id={`coin_prices${item.id}`} >{parseFloat(item.price)}</span><br/>
        <span style={{color:'rgba(255, 255, 255, 0.5)', fontSize:'10px'}}>
        <img src={`${bUrl}storage/${countrydetails.symbol}`} style={{width:'20px', height:'10px'}} /> <span id={`c_p${item.id}`}>{parseFloat((item.price*countrydetails.normalvalue).toFixed(4))}</span>
        </span> 
    </td>
    <td className="col-1" style={{textAlign:'right'}}>
        {item.change_percentage>=0 &&
        <span className='badge' style={{background:'green'}} id={`percent${item.id}`} >{item.change_percentage}%</span>
        }
        {item.change_percentage<0 && 
        <span className='badge' style={{background:'red'}} id={`percent${item.id}`} >{item.change_percentage}%</span>
        }
    </td>
    {/* <Liveprice coin={item.coin_code} pair={item.pair_with} uniid={item.id} countryprice={countrydetails.value} /> */}
    </tr>
    ))}
    </tbody>
    }
</table>
{/* </InfiniteScroll> */}
</div>
</div>


</div>
</div>

</div>
</div>
</div>

</motion.div>
  )
}
