import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Authuser from '../../api/Authuser';
// import Livehotvol from './Livehotvol';

export default function Hotvolcoinprice(props) {
let canceltoken;
const {http} = Authuser();
const [hotvolcoinprice,setHotvolcoinprice] = useState([]);
useEffect(()=>{
    gethotvolcoinprice();
},[]);

const gethotvolcoinprice = () =>{
canceltoken = axios.CancelToken.source();  
http.get('/hot-vol-coin-price/'+props.pair+'/'+props.coin,{canceltoken:canceltoken.token}).then(res=>{
    setHotvolcoinprice(res.data);
});
}

  return (
    <>

    <td style={{textAlign:'right'}} id={`coin_prices${props.unid}`}>{parseFloat(hotvolcoinprice.coinprice)}</td>
    <td style={{textAlign:'right'}}>
    {hotvolcoinprice.percentage>=0 &&
       <span className="badge" style={{background:'green'}} id={`percent${props.unid}`} >{hotvolcoinprice.percentage}</span>  
    }
    {hotvolcoinprice.percentage<0 &&
       <span className="badge" style={{background:'red'}} id={`percent${props.unid}`} >{hotvolcoinprice.percentage}</span>  
    }
    </td>
    {/* <Livehotvol coin={props.coin} pair={props.pair} uniid={props.unid} /> */}

    </>
  )
}
